<app-form-card
  titleIcon="fact_check"
  cardTitle="RESPONSIBILITY_CHECK_FAIL.HEADING">
  @if ((appState$ | async)?.formData?.selectedModule; as module) {
    <div>
      <h2>
        {{ 'RESPONSIBILITY_CHECK_FAIL.SUBHEADING' | translate }}
      </h2>
      <p>
        {{
          'RESPONSIBILITY_CHECK_FAIL.' + module + '.FIRST_PARAGRAPH_SENTENCE_1'
            | translate
        }}
        <br />
        {{
          'RESPONSIBILITY_CHECK_FAIL.' + module + '.FIRST_PARAGRAPH_SENTENCE_2'
            | translate
        }}
        <a
          [href]="'RESPONSIBILITY_CHECK_FAIL.' + module + '.LINK' | translate"
          target="_blank"
          rel="noopener noreferrer"
          class="bold"
          >{{ 'RESPONSIBILITY_CHECK_FAIL.' + module + '.LINK' | translate }}</a
        >.
        {{
          'RESPONSIBILITY_CHECK_FAIL.' + module + '.FIRST_PARAGRAPH_SENTENCE_3'
            | translate
        }}
      </p>
      <p>
        {{
          'RESPONSIBILITY_CHECK_FAIL.' + module + '.SECOND_PARAGRAPH'
            | translate
        }}
      </p>
    </div>
  }
  <app-form-navigation
    slot="navigation"
    [nextConfig]="{ label: 'RESPONSIBILITY_CHECK_FAIL.PROCEED_ANYWAY_BUTTON' }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
