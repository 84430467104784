<app-form-card
  titleIcon="home"
  cardTitle="GRID_CONNECTION_PROPERTY_TYPE.HEADING"
  dataTestId="grid-connection-building-type-heading">
  <h2 class="full-width">
    {{ 'GRID_CONNECTION_PROPERTY_TYPE.DESCRIPTION' | translate }}
  </h2>
  <form [formGroup]="propertyTypeForm">
    <app-selection-group>
      @for (property of propertyDetailsList; track property) {
        <app-selection-card
          [icon]="property.icon"
          [label]="property.label"
          [selected]="selectedPropertyType === property.type"
          (click)="selectPropertyType(property.type)"
          [attr.data-testid]="property.type">
        </app-selection-card>
      }

      @if (allTouched && !selectedPropertyType) {
        <mat-error>{{ 'VALIDATION.REQUIRED_SELECTION' | translate }}</mat-error>
      }
    </app-selection-group>

    <div class="space"></div>

    @if (selectedPropertyType) {
      <div class="form-fields">
        @if (
          selectedPropertyType === gridConnectionPropertyType.MULTI_FAMILY_HOUSE
        ) {
          <div class="flat-count-container">
            <h2>
              {{
                'GRID_CONNECTION_PROPERTY_TYPE.FORM.MORE_INFORMATION'
                  | translate
              }}
            </h2>
            <div class="input-container">
              <mat-form-field appearance="outline" class="input-field">
                <mat-label>{{
                  'GRID_CONNECTION_PROPERTY_TYPE.FORM.FLAT_COUNT' | translate
                }}</mat-label>
                <input
                  matInput
                  type="number"
                  name="flatCount"
                  formControlName="flatCount"
                  min="0" />
                <mat-error appErrorMessages="flatCount"></mat-error>
              </mat-form-field>
            </div>
          </div>
        }
        @if (
          selectedPropertyType === gridConnectionPropertyType.INDUSTRY ||
          selectedPropertyType === gridConnectionPropertyType.MIXED
        ) {
          <div class="area-container">
            <h2>
              {{
                'GRID_CONNECTION_PROPERTY_TYPE.FORM.MORE_INFORMATION'
                  | translate
              }}
            </h2>
            <div class="input-container">
              <mat-form-field appearance="outline" class="input-field">
                <mat-label>
                  {{ 'GRID_CONNECTION_PROPERTY_TYPE.FORM.AREA' | translate }}
                </mat-label>
                <input
                  matInput
                  type="number"
                  name="area"
                  formControlName="area"
                  min="0" />
                <mat-error appErrorMessages="area"></mat-error>
              </mat-form-field>
            </div>
          </div>
        }
        <mat-checkbox color="primary" formControlName="basementAvailable">{{
          'GRID_CONNECTION_PROPERTY_TYPE.FORM.BASEMENT_QUESTION' | translate
        }}</mat-checkbox>
        <mat-checkbox color="primary" formControlName="externalConnection">{{
          'GRID_CONNECTION_PROPERTY_TYPE.FORM.EXTERNAL_CONNECTION_QUESTION'
            | translate
        }}</mat-checkbox>
      </div>
    }
  </form>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: propertyTypeForm.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
