import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FLOW_MODULE } from '@app/models/form-data.interface';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import {
  PublicCustomerConfigurationDto,
  WhiteLabelService,
} from '@app/services/white-label.service';
import { filter, map, Subject, take, takeUntil } from 'rxjs';

interface ModuleDetails {
  enabled: boolean;
  name: FLOW_MODULE;
  icon?: string;
  svgIcon?: string;
  label: string;
  symbolOutlinedIcon?: boolean;
}

@Component({
  selector: 'app-module-selection',
  templateUrl: './module-selection.component.html',
})
export class ModuleSelectionComponent implements OnInit, OnDestroy {
  public selectedModule?: FLOW_MODULE;
  public moduleSelectionForm!: FormGroup;
  public allTouched = false;
  public module = FLOW_MODULE;
  public readonly moduleDetailsList: ModuleDetails[] = [
    {
      enabled: false,
      name: FLOW_MODULE.ELECTRICITY,
      label: 'MODULE_SELECTION.ELECTRICITY',
      icon: 'electric_bolt',
      symbolOutlinedIcon: false,
    },
    {
      enabled: false,
      name: FLOW_MODULE.GAS,
      label: 'MODULE_SELECTION.GAS',
      svgIcon: 'gas',
      symbolOutlinedIcon: true,
    },
    {
      enabled: false,
      name: FLOW_MODULE.WATER,
      label: 'MODULE_SELECTION.WATER',
      icon: 'water',
    },
    {
      enabled: false,
      name: FLOW_MODULE.HEAT,
      label: 'MODULE_SELECTION.HEAT',
      svgIcon: 'heat',
    },
  ];

  private commissioningModules: FLOW_MODULE[] = [];
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private routeService: RouteService,
    private whiteLabelService: WhiteLabelService
  ) {}

  public ngOnInit(): void {
    this.evalEnabledFlows();
    this.createModuleSelectionForm();
    this.updateForm();
    this.watchForm();
  }

  private evalEnabledFlows(): void {
    this.whiteLabelService.whiteLabelConfig$
      .pipe(filter(Boolean), take(1))
      .subscribe(config => {
        const allModules = this.getAllModules(config);
        this.moduleDetailsList.forEach(module => {
          module.enabled = allModules.includes(module.name);
        });
      });
  }

  private createModuleSelectionForm(): void {
    this.moduleSelectionForm = this.formBuilder.group({
      selectedModule: [null, [Validators.required]],
    });
  }

  private updateForm(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.selectedModule),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$)
      )
      .subscribe(selectedModule => {
        this.moduleSelectionForm
          .get('selectedModule')
          ?.setValue(selectedModule);
        this.selectedModule = selectedModule;
      });
  }

  private watchForm(): void {
    this.moduleSelectionForm
      .get('selectedModule')
      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(selectedModule => {
        this.appStateService.updateFormData({
          selectedModule,
        });
      });
  }

  public selectModule(selectedModule: FLOW_MODULE): void {
    if (this.selectedModule !== selectedModule) {
      this.appStateService.clearState(true, true);
      this.moduleSelectionForm.get('selectedModule')?.setValue(selectedModule);
      this.selectedModule = selectedModule;
    }
  }

  private getAllModules(
    customerConfigurationDto: PublicCustomerConfigurationDto
  ): FLOW_MODULE[] {
    this.commissioningModules = customerConfigurationDto.commissioningModules;
    return [
      ...new Set([
        ...(this.commissioningModules || []),
        ...(customerConfigurationDto?.moduleSettings?.map(
          setting => setting.module
        ) || []),
      ]),
    ];
  }

  public hasCommissioningModule(selectedModule: FLOW_MODULE): boolean {
    return this.commissioningModules.includes(selectedModule);
  }

  public previous(): void {
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.moduleSelectionForm.valid) {
      this.routeService.navigateToFlowSelectionPage();
    } else {
      this.allTouched = true;
      this.moduleSelectionForm.markAllAsTouched();
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
