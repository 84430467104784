<div class="card-container">
  <div class="card">
    @if (titleIcon || svgIcon) {
      <mat-icon
        aria-hidden="false"
        aria-label="Card icon"
        [fontIcon]="titleIcon || ''"
        [svgIcon]="svgIcon || ''"
        class="card-icon material-icons-outlined"></mat-icon>
    }
    @if (cardTitle) {
      <h1 [attr.data-testid]="dataTestId">
        {{ cardTitle | translate }}
      </h1>
    }
    @if (description) {
      <p class="description">
        {{ description | translate }}
      </p>
    }
    <div class="content">
      <ng-content></ng-content>
    </div>
    <div class="navigation">
      <ng-content select="[slot=navigation]"></ng-content>
    </div>
  </div>
</div>
