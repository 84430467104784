<app-summary-section
  heading="COMMISSIONING_ELECTRICITY.SYSTEM.HEADING"
  [stepRoute]="electricitySystemsRoute">
  <app-summary-section-field
    label="COMMISSIONING_ELECTRICITY.SYSTEM.METER_CHANGES"
    [value]="(meterChanges ? 'COMMON.YES' : 'COMMON.NO') | translate">
  </app-summary-section-field>
  @if (electricitySystems) {
    @for (system of selectedSystems; track system) {
      <p class="section-heading">
        {{
          'COMMISSIONING_ELECTRICITY.SYSTEM.TYPES.' + system.type | translate
        }}
      </p>
      <div class="section-fields-container">
        @if (system.systemPower) {
          <app-summary-section-field
            label="COMMISSIONING_ELECTRICITY.SYSTEM.SYSTEM_POWER"
            [value]="system.systemPower">
          </app-summary-section-field>
        }
        @if (system.systemCount) {
          <app-summary-section-field
            label="COMMISSIONING_ELECTRICITY.SYSTEM.SYSTEM_COUNT"
            [value]="system.systemCount">
          </app-summary-section-field>
        }
        @if (system.systemType) {
          <app-summary-section-field
            label="COMMISSIONING_ELECTRICITY.SYSTEM.SYSTEM_TYPE"
            [value]="system.systemType">
          </app-summary-section-field>
        }
        @if (system.notes) {
          <app-summary-section-field
            label="COMMISSIONING_ELECTRICITY.SYSTEM.NOTES"
            [value]="system.notes">
          </app-summary-section-field>
        }
      </div>
    }
  }
</app-summary-section>

@if (meterChanges) {
  @if (complexMeasurementDetails) {
    <app-complex-measurement-details-summary
      [measurementDetails]="
        complexMeasurementDetails
      "></app-complex-measurement-details-summary>
  }
} @else {
  @if (simpleMeasurementDetails) {
    <app-simple-measurement-details-summary
      [simpleMeasurementDetails]="
        simpleMeasurementDetails
      "></app-simple-measurement-details-summary>
  }
}
