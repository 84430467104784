@if (buildingDetails) {
  <app-summary-section
    heading="COMMISSIONING_ELECTRICITY.PROPERTY_TYPE.HEADING"
    [stepRoute]="buildingDetailsRoute">
    <app-summary-building-type
      [buildingType]="buildingDetails.buildingType"></app-summary-building-type>
    @if (buildingDetails.area) {
      <app-summary-section-field
        label="COMMISSIONING_ELECTRICITY.PROPERTY_TYPE.AREA"
        [value]="buildingDetails.area">
      </app-summary-section-field>
    }
    @if (buildingDetails.flatCount) {
      <app-summary-section-field
        label="COMMISSIONING_ELECTRICITY.PROPERTY_TYPE.FLAT_COUNT"
        [value]="buildingDetails.flatCount">
      </app-summary-section-field>
    }
  </app-summary-section>
}

@if (electricityInformation) {
  <app-summary-section
    heading="COMMISSIONING_ELECTRICITY.INFORMATION.HEADING"
    [stepRoute]="electricityInformationRoute">
    <p class="section-heading">
      {{ 'COMMISSIONING_ELECTRICITY.INFORMATION.SUBHEADING' | translate }}
    </p>
    <app-summary-section-field
      label="COMMISSIONING_ELECTRICITY.INFORMATION.SYSTEM_POWER"
      [value]="electricityInformation.systemPower">
    </app-summary-section-field>
    <app-summary-section-field
      label="COMMISSIONING_ELECTRICITY.INFORMATION.CONNECTION_FUSE"
      [value]="electricityInformation.connectionFuse">
    </app-summary-section-field>
    <app-summary-section-field
      label="COMMISSIONING_ELECTRICITY.INFORMATION.CONSTRUCTION_ELECTRICITY"
      [value]="
        (electricityInformation.constructionElectricity
          ? 'COMMON.YES'
          : 'COMMON.NO'
        ) | translate
      ">
    </app-summary-section-field>
  </app-summary-section>
}
