import {
  Component,
  computed,
  inject,
  signal,
  WritableSignal,
} from '@angular/core';
import {
  COMMISSIONING_FLOW_TYPE,
  CommissioningMappedData,
} from '@app/models/commissioning-mapped-data.interface';
import { AppState, FLOW, FLOW_MODULE } from '@app/models/form-data.interface';
import {
  BUILDING_TYPE,
  FORM_TYPE,
  GAS_OPTION,
  GRID_CONNECTION_TYPE,
  PV_TYPE,
  RegistrationForm,
  SEWAGE_OPTION,
  WATER_OPTION,
} from '@app/models/registration-form';
import { ROUTES } from '@app/models/routes.enum';
import { AppStateService } from '@app/services/app-state.service';
import { CommissioningFormMapperService } from '@app/services/commissioning-form-mapper.service';
import { FormMapperService } from '@app/services/form-mapper.service';
import { ConnectionRequestService } from '@app/services/connection-request.service';
import { RouteService } from '@app/services/route.service';
import { SnackBarService } from '@app/services/snack-bar.service';
import { WhiteLabelService } from '@app/services/white-label.service';
import { TranslateService } from '@ngx-translate/core';
import {
  combineLatest,
  filter,
  finalize,
  map,
  Observable,
  Subject,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { EnvironmentService } from '@app/services/environment.service';
import { HttpErrorResponse } from '@angular/common/http';
import { getLanguageLocaleCulture } from '@app/constants/language';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent {
  readonly #translateService: TranslateService = inject(TranslateService);
  readonly #appStateService: AppStateService = inject(AppStateService);
  readonly #connectionRequestService: ConnectionRequestService = inject(
    ConnectionRequestService
  );
  readonly #snackBarService: SnackBarService = inject(SnackBarService);
  readonly #routeService: RouteService = inject(RouteService);
  readonly #formMapperService: FormMapperService = inject(FormMapperService);
  readonly #commissioningFormMapperService: CommissioningFormMapperService =
    inject(CommissioningFormMapperService);
  readonly #environmentService: EnvironmentService = inject(EnvironmentService);
  readonly #whiteLabelService = inject(WhiteLabelService);

  #selectedModule: FLOW_MODULE | undefined;
  #isCommissioningFlow = false;
  public readonly state$: Observable<AppState> = this.#appStateService
    .observeState()
    .pipe(
      tap(state => {
        this.#selectedModule = state.formData.selectedModule;
        this.#isCommissioningFlow =
          state.formData.selectedFlow === FLOW.COMMISSIONING;
      })
    );
  public readonly loading = signal(false);
  public readonly formType = FORM_TYPE;
  public readonly pvType = PV_TYPE;
  public readonly gridConnectionType = GRID_CONNECTION_TYPE;
  public readonly buildingType = BUILDING_TYPE;
  public readonly gasOptions = GAS_OPTION;
  public readonly waterOptions = WATER_OPTION;
  public readonly sewageOptions = SEWAGE_OPTION;
  public readonly routes = ROUTES;
  public readonly module = FLOW_MODULE;
  public readonly turnstileSiteKey = this.#environmentService.turnstileSiteKey;
  public readonly acceptPrivacyPolicy = signal(false);
  public readonly acceptCommunication = signal(false);
  public readonly commissioningFlowSelectionRoutes = new Map<
    FLOW_MODULE,
    ROUTES
  >([[FLOW_MODULE.ELECTRICITY, ROUTES.COMMISSIONING_ELECTRICITY_OPTIONS]]);
  public readonly FLOW = FLOW;
  public readonly COMMISSIONING_FLOW_TYPE = COMMISSIONING_FLOW_TYPE;
  public readonly turnstileCaptchaReset: Subject<void> = new Subject();
  public readonly whiteLabelConfig$ = this.#whiteLabelService.whiteLabelConfig$;
  public currentLanguageCulture = getLanguageLocaleCulture(
    this.#translateService.currentLang
  );
  public areMultipleFlowsEnabled$: Observable<boolean> =
    this.#whiteLabelService.whiteLabelConfig$.pipe(
      filter(Boolean),
      map(config =>
        this.#appStateService.isFlowSelectionAvailableForSelectedModule(
          this.#selectedModule,
          config
        )
      )
    );
  readonly #turnstileCaptchaToken: WritableSignal<string | null> = signal(null);
  public readonly nextDisabled = computed(
    () =>
      !this.#turnstileCaptchaToken() ||
      this.loading() ||
      !(this.acceptPrivacyPolicy() && this.acceptCommunication())
  );

  public onTokenResolve($event: string | null) {
    this.#turnstileCaptchaToken.update(() => $event);
  }

  #resetCaptcha() {
    this.turnstileCaptchaReset.next();
  }

  public back() {
    this.#routeService.navigateToPreviousStep();
  }

  public submitData() {
    if (!this.#turnstileCaptchaToken()) {
      this.#snackBarService.openSnackBar(
        'SHARED.SNACKBAR.CAPTCHA_MISSING',
        false
      );
      return;
    }
    const verifiedToken = this.#turnstileCaptchaToken();

    this.loading.update(() => true);
    combineLatest([
      this.#whiteLabelService.whiteLabelConfig$.pipe(
        filter(Boolean),
        map(({ id, documentsUploadEnabled }) => {
          return {
            customerConfigurationId: id,
            documentsUploadEnabled,
          };
        })
      ),
      this.state$.pipe(map(({ formData }) => formData)),
    ])
      .pipe(
        take(1),
        map(
          ([
            { customerConfigurationId, documentsUploadEnabled },
            formDataObject,
          ]) => {
            const mappedData = this.#isCommissioningFlow
              ? this.#commissioningFormMapperService.mapCommissioningFormData(
                  customerConfigurationId,
                  formDataObject,
                  documentsUploadEnabled
                )
              : this.#formMapperService.mapFormData(
                  customerConfigurationId,
                  formDataObject,
                  documentsUploadEnabled
                );
            return {
              mappedData: mappedData,
              files: this.#formMapperService.mapFiles(formDataObject),
            };
          }
        ),
        switchMap(mapResult => {
          const request: Observable<
            RegistrationForm | CommissioningMappedData
          > = this.#isCommissioningFlow
            ? this.#connectionRequestService.submitCommissioningForm(
                mapResult.mappedData as CommissioningMappedData,
                verifiedToken,
                mapResult.files
              )
            : this.#connectionRequestService.submitRegistrationForm(
                mapResult.mappedData as RegistrationForm,
                verifiedToken,
                mapResult.files
              );
          return request.pipe(finalize(() => this.loading.update(() => false)));
        })
      )
      .subscribe({
        next: () => {
          this.#appStateService.clearState();
          this.#routeService.navigateToNextStep();
          this.#snackBarService.openSnackBar('SUMMARY.SAVE_SUCCESSFUL', true);
        },
        error: error => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            this.#resetCaptcha();
            this.#snackBarService.openSnackBar(
              'SHARED.SNACKBAR.CAPTCHA_MISSING',
              false
            );
          } else {
            this.#routeService.navigateToErrorPage();
            this.#snackBarService.openSnackBar(
              'SUMMARY.SAVE_NOT_SUCCESSFUL',
              false
            );
          }
        },
      });
  }
}
