<app-form-card titleIcon="category" cardTitle="GAS.GAS_OPTIONS.HEADING">
  <h2 class="full-width">
    {{ 'GAS.GAS_OPTIONS.DESCRIPTION' | translate }}
  </h2>
  <app-selection-group>
    @for (gasOption of gasOptionsList; track gasOption) {
      <app-selection-card
        class="selection-card"
        [icon]="gasOption.icon"
        [label]="gasOption.label"
        [selected]="selectedOption === gasOption.type"
        (click)="selectType(gasOption.type)">
      </app-selection-card>
    }
    @if (!selectedOption && allTouched) {
      <mat-error>{{ 'VALIDATION.REQUIRED_SELECTION' | translate }}</mat-error>
    }
  </app-selection-group>

  @if (selectedOption) {
    <div class="space"></div>
    <form [formGroup]="gasOptionsForm">
      @switch (selectedOption) {
        @case (gasOptions.NEW) {
          <app-hint>
            <div>
              {{ 'GAS.GAS_OPTIONS.NEW.INFO' | translate }}
              <ul class="list-container">
                <li>
                  {{ 'SHARED.DOCUMENT_TYPE.SITE_PLAN' | translate }}
                </li>
                <li>
                  {{ 'SHARED.DOCUMENT_TYPE.FLOOR_PLAN' | translate }}
                </li>
              </ul>
            </div>
          </app-hint>
        }
        @case (gasOptions.CHANGE) {
          <div class="gas-change-container">
            <app-hint>
              <div>
                {{ 'GAS.GAS_OPTIONS.CHANGE.INFO' | translate }}
                <ul class="list-container">
                  <li>
                    {{ 'SHARED.DOCUMENT_TYPE.SITE_PLAN' | translate }}
                  </li>
                </ul>
              </div>
            </app-hint>
            <div class="space"></div>
            <h2>
              {{
                'GAS.GAS_OPTIONS.CHANGE.CONNECTION_CHANGE_QUESTION' | translate
              }}
            </h2>
            <mat-checkbox formControlName="changePower" color="primary">{{
              'GAS.GAS_OPTIONS.CHANGE.CHANGE_POWER_LABEL' | translate
            }}</mat-checkbox>
            <mat-checkbox formControlName="relocate" color="primary">{{
              'GAS.GAS_OPTIONS.CHANGE.RELOCATE_LABEL' | translate
            }}</mat-checkbox>
            <div class="space"></div>
            @if (changePowerControl?.value) {
              <div>
                <h2 class="tooltip-heading-label">
                  {{ 'GAS.GAS_OPTIONS.CHANGE.CHANGE_POWER_LABEL' | translate }}
                  <app-info-tooltip
                    tooltipText="GAS.GAS_OPTIONS.CHANGE.CHANGE_POWER_TOOLTIP">
                  </app-info-tooltip>
                </h2>
                <div class="input-container">
                  <mat-form-field appearance="outline" class="input-field">
                    <mat-label>{{
                      'GAS.GAS_OPTIONS.CHANGE.CURRENT_POWER_DEMAND_PLACEHOLDER'
                        | translate
                    }}</mat-label>
                    <input
                      matInput
                      type="number"
                      name="currentPowerDemand"
                      formControlName="currentPowerDemand"
                      min="0" />
                    <mat-error
                      appErrorMessages="currentPowerDemand"></mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="input-field">
                    <mat-label>{{
                      'GAS.GAS_OPTIONS.CHANGE.PLANNED_POWER_DEMAND_PLACEHOLDER'
                        | translate
                    }}</mat-label>
                    <input
                      matInput
                      type="number"
                      name="plannedPowerDemand"
                      formControlName="plannedPowerDemand"
                      min="0" />
                    <mat-error
                      appErrorMessages="plannedPowerDemand"></mat-error>
                  </mat-form-field>
                </div>
                <div class="space"></div>
              </div>
            }
            @if (relocateControl?.value) {
              <div>
                <h2 class="tooltip-heading-label">
                  {{ 'GAS.GAS_OPTIONS.CHANGE.RELOCATE_LABEL' | translate }}
                  <app-info-tooltip
                    tooltipText="GAS.GAS_OPTIONS.CHANGE.RELOCATE_TOOLTIP">
                  </app-info-tooltip>
                </h2>
                <div class="input-container">
                  <mat-form-field appearance="outline" class="input-field">
                    <mat-label>{{
                      'GAS.GAS_OPTIONS.CHANGE.DITCH_LENGTH_PLACEHOLDER'
                        | translate
                    }}</mat-label>
                    <input
                      matInput
                      type="number"
                      name="ditchLength"
                      formControlName="ditchLength"
                      min="0" />
                    <mat-error appErrorMessages="ditchLength"></mat-error>
                  </mat-form-field>
                </div>
                <div class="space"></div>
              </div>
            }
          </div>
        }
        @case (gasOptions.DETACH) {
          <div>
            <app-hint>
              <div>
                {{ 'GAS.GAS_OPTIONS.DETACH.INFO' | translate }}
                <ul class="list-container">
                  <li>
                    {{ 'SHARED.DOCUMENT_TYPE.PROOF_OF_OWNERSHIP' | translate }}
                  </li>
                  <li>
                    {{ 'SHARED.DOCUMENT_TYPE.SITE_PLAN' | translate }}
                  </li>
                </ul>
              </div>
            </app-hint>
            <div class="space"></div>
            <h2>
              {{
                ('GAS.GAS_OPTIONS.DETACH.PARTIAL_DECONSTRUCTION_DESIRED_QUESTION'
                  | translate) + ' *'
              }}
            </h2>
            <mat-radio-group
              formControlName="partialDeconstructionDesired"
              class="input-container">
              <div>
                <mat-radio-button color="primary" [value]="true">{{
                  'COMMON.YES' | translate
                }}</mat-radio-button>
                <mat-radio-button color="primary" [value]="false">
                  {{ 'COMMON.NO' | translate }}
                </mat-radio-button>
              </div>
              <mat-error
                appErrorMessages="partialDeconstructionDesired"
                [hideError]="!allTouched"></mat-error>
            </mat-radio-group>
            <h2 class="tooltip-heading-label">
              {{ 'GAS.GAS_OPTIONS.DETACH.METER_NUMBER' | translate }}
              <app-info-tooltip
                tooltipText="GAS.GAS_OPTIONS.DETACH.METER_NUMBER_TOOLTIP">
              </app-info-tooltip>
            </h2>
            <div class="input-container">
              <mat-form-field appearance="outline" class="input-field">
                <mat-label>
                  {{ 'GAS.GAS_OPTIONS.DETACH.METER_NUMBER' | translate }}
                </mat-label>
                <input
                  matInput
                  type="text"
                  name="meterNumber"
                  formControlName="meterNumber" />
                <mat-error appErrorMessages="meterNumber"></mat-error>
              </mat-form-field>
            </div>
          </div>
        }
      }
    </form>
  }

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: gasOptionsForm.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
