<app-form-card titleIcon="contact_support" cardTitle="ERROR.HEADING">
  <div class="space"></div>
  <div class="space"></div>

  <p>
    {{ 'ERROR.DESCRIPTION' | translate }}
  </p>
  @if (!hasWhitelabel) {
    <p>
      {{
        'RESPONSIBILITY_CHECK_FAIL.ELECTRICITY.FIRST_PARAGRAPH_SENTENCE_2'
          | translate
      }}
      <a
        [href]="'RESPONSIBILITY_CHECK_FAIL.ELECTRICITY.LINK' | translate"
        target="_blank"
        rel="noopener noreferrer"
        class="bold"
        >{{ 'RESPONSIBILITY_CHECK_FAIL.ELECTRICITY.LINK' | translate }}</a
      >.
      {{
        'RESPONSIBILITY_CHECK_FAIL.ELECTRICITY.FIRST_PARAGRAPH_SENTENCE_3'
          | translate
      }}
    </p>
  }

  <div class="space"></div>

  <div class="error-icons-container">
    <div class="error-icons">
      <mat-icon fontIcon="problem" class="error-icon material-symbols-outlined">
      </mat-icon>
      <mat-icon
        fontIcon="bug_report"
        class="error-icon material-symbols-outlined">
      </mat-icon>
      <mat-icon fontIcon="error" class="error-icon material-symbols-outlined">
      </mat-icon>
    </div>
  </div>

  <app-form-navigation
    slot="navigation"
    [previousConfig]="{ visible: false }"
    [nextConfig]="nextConfig"
    (next)="tryAgain()">
  </app-form-navigation>
</app-form-card>
