import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import {
  FLOW,
  FLOW_MODULE,
  FormDataObject,
  OtherData,
} from '@app/models/form-data.interface';
import {
  FORM_TYPE,
  GAS_OPTION,
  GRID_CONNECTION_TYPE,
  HEAT_OPTION,
  PV_TYPE,
  SEWAGE_OPTION,
  WATER_OPTION,
} from '@app/models/registration-form';
import { ROUTES } from '@app/models/routes.enum';
import { EnvironmentService } from '@app/services/environment.service';
import { filter } from 'rxjs';
import { AppStateService } from './app-state.service';
import {
  PublicCustomerConfigurationDto,
  WhiteLabelService,
} from './white-label.service';
import {
  COMMISSIONING_FLOW_TYPE,
  MainPowerSupplyType,
} from '@app/models/commissioning-mapped-data.interface';

type RouteHandler = () => ROUTES;
type RouteHandlers = Partial<Record<ROUTES, RouteHandler>> & {
  default: RouteHandler;
};
@Injectable({
  providedIn: 'root',
})
export class RouteService {
  private readonly navigationExtras: NavigationExtras = {
    skipLocationChange: true,
  };

  private readonly allowedRoutesOnAppStart: Array<ROUTES | undefined> = [
    undefined,
    ROUTES.MODULE_SELECTION,
    ROUTES.MAIN_DOMAIN_PAGE,
    ROUTES.ERROR,
  ];

  private readonly allowedRoutesWithEmptyState: Array<ROUTES | undefined> = [
    undefined,
    ROUTES.MODULE_SELECTION,
    ROUTES.FLOW_SELECTION,
    ROUTES.MAIN_DOMAIN_PAGE,
    ROUTES.ADDRESS,
    ROUTES.SUCCESS,
    ROUTES.ERROR,
  ];

  private readonly startingRoute: ROUTES = ROUTES.MODULE_SELECTION;
  private currentRoute?: ROUTES;
  private formData!: FormDataObject;
  private otherData!: OtherData;
  private customerConfigurationDto?: PublicCustomerConfigurationDto;
  private readonly previousStepRouteHandlers: RouteHandlers = {
    [ROUTES.FLOW_SELECTION]: () => ROUTES.MODULE_SELECTION,
    [ROUTES.ADDRESS]: () => {
      return this.evalEnabledFlowsPreviousRoute();
    },
    [ROUTES.RESPONSIBILITY_CHECK_FAIL]: () => ROUTES.ADDRESS,
    [ROUTES.GAS_OPTIONS]: () =>
      this.otherData.responsibilityCheckFail
        ? ROUTES.RESPONSIBILITY_CHECK_FAIL
        : ROUTES.ADDRESS,
    [ROUTES.ELECTRICITY_PRODUCT_SELECTION]: () =>
      this.otherData.responsibilityCheckFail
        ? ROUTES.RESPONSIBILITY_CHECK_FAIL
        : ROUTES.ADDRESS,
    [ROUTES.COMMISSIONING_ELECTRICITY_OPTIONS]: () =>
      this.otherData.responsibilityCheckFail
        ? ROUTES.RESPONSIBILITY_CHECK_FAIL
        : ROUTES.ADDRESS,
    // Commissioning electricity routes
    [ROUTES.COMMISSIONING_ELECTRICITY_PROPERTY_TYPE]: () =>
      ROUTES.COMMISSIONING_ELECTRICITY_MAIN_POWER_SUPPLY_TYPE,
    [ROUTES.COMMISSIONING_ELECTRICITY_SYSTEMS]: () =>
      ROUTES.COMMISSIONING_ELECTRICITY_OPTIONS,
    // Commissioning main power supply
    [ROUTES.COMMISSIONING_ELECTRICITY_MAIN_POWER_SUPPLY_TYPE]: () =>
      ROUTES.COMMISSIONING_ELECTRICITY_OPTIONS,
    [ROUTES.COMMISSIONING_ELECTRICITY_INFORMATION]: () =>
      ROUTES.COMMISSIONING_ELECTRICITY_PROPERTY_TYPE,
    [ROUTES.COMMISSIONING_ELECTRICITY_MAIN_POWER_SUPPLY_POWER_INCREASE_DETAILS]:
      () => ROUTES.COMMISSIONING_ELECTRICITY_MAIN_POWER_SUPPLY_TYPE,
    [ROUTES.COMMISSIONING_ELECTRICITY_CONTACT]: () =>
      this.getDocumentUploadPreviousRoute(),
    // Commissioning customer facility
    [ROUTES.COMMISSIONING_ELECTRICITY_SIMPLE_MEASUREMENT_DETAILS]: () =>
      ROUTES.COMMISSIONING_ELECTRICITY_SYSTEMS,
    [ROUTES.COMMISSIONING_ELECTRICITY_MEASUREMENT_DETAILS]: () =>
      ROUTES.COMMISSIONING_ELECTRICITY_SYSTEMS,
    // Gas module specific routes
    [ROUTES.GAS_PROPERTY_TYPE]: () => ROUTES.GAS_OPTIONS,
    [ROUTES.GAS_POWER_REQUIREMENT]: () => ROUTES.GAS_PROPERTY_TYPE,
    // Water module specific routes
    // Sewage
    [ROUTES.SEWAGE_OPTIONS]: () => ROUTES.WATER_AREA,
    [ROUTES.SEWAGE_TYPE]: () => ROUTES.WATER_PROPERTY_TYPE,
    // Construction Water
    [ROUTES.CONSTRUCTION_WATER_REQUIREMENTS]: () => ROUTES.WATER_AREA,
    // Water
    [ROUTES.WATER_AREA]: () => ROUTES.ADDRESS,
    [ROUTES.WATER_OPTIONS]: () => ROUTES.WATER_AREA,
    [ROUTES.WATER_PROPERTY_TYPE]: () =>
      this.formData.waterArea === FORM_TYPE.SEWAGE
        ? ROUTES.SEWAGE_OPTIONS
        : ROUTES.WATER_OPTIONS,
    [ROUTES.WATER_REQUIREMENTS]: () => ROUTES.WATER_PROPERTY_TYPE,
    // Electricity module specific routes
    [ROUTES.CHANGE_PV_DETAILS]: () => ROUTES.PV_TYPE,
    [ROUTES.PV_TYPE]: () => ROUTES.ELECTRICITY_PRODUCT_SELECTION,
    [ROUTES.PV_FACILITY_INFORMATION]: () => ROUTES.PV_TYPE,
    [ROUTES.STORAGE_INFORMATION]: () =>
      this.formData.formType === FORM_TYPE.STORAGE
        ? ROUTES.ELECTRICITY_PRODUCT_SELECTION
        : ROUTES.PV_FACILITY_INFORMATION,
    [ROUTES.CHARGING_DEVICE_OPTIONS]: () =>
      ROUTES.ELECTRICITY_PRODUCT_SELECTION,
    [ROUTES.CHARGING_DEVICE_MORE_OPTIONS]: () => ROUTES.CHARGING_DEVICE_OPTIONS,
    [ROUTES.CHARGING_DEVICE_PROPERTY_TYPE]: () =>
      ROUTES.CHARGING_DEVICE_MORE_OPTIONS,
    [ROUTES.BALCONY_PV]: () => ROUTES.ELECTRICITY_PRODUCT_SELECTION,
    [ROUTES.BALCONY_PV_EXIT]: () => ROUTES.BALCONY_PV,
    [ROUTES.GRID_CONNECTION_TYPE]: () => ROUTES.ELECTRICITY_PRODUCT_SELECTION,
    [ROUTES.GRID_CONNECTION_PROPERTY_TYPE]: () => ROUTES.GRID_CONNECTION_TYPE,
    [ROUTES.GRID_CONNECTION_CONSUMER]: () =>
      ROUTES.GRID_CONNECTION_PROPERTY_TYPE,
    [ROUTES.GRID_CONNECTION_CHANGE]: () => ROUTES.GRID_CONNECTION_TYPE,
    [ROUTES.CONSTRUCTION_ELECTRICITY_INFORMATION]: () =>
      ROUTES.GRID_CONNECTION_TYPE,
    [ROUTES.POWER_CHANGE_DETAILS]: () =>
      this.formData.gridConnectionType === GRID_CONNECTION_TYPE.CHANGE
        ? ROUTES.GRID_CONNECTION_CHANGE
        : ROUTES.GRID_CONNECTION_TYPE,
    [ROUTES.HEAT_PUMP]: () => ROUTES.ELECTRICITY_PRODUCT_SELECTION,
    [ROUTES.OTHER]: () => ROUTES.ELECTRICITY_PRODUCT_SELECTION,
    // Heat module specific routes
    [ROUTES.HEAT_OPTIONS]: () => ROUTES.ADDRESS,
    [ROUTES.HEAT_PROPERTY_TYPE]: () => ROUTES.HEAT_OPTIONS,
    [ROUTES.HEAT_REQUIREMENTS]: () => ROUTES.HEAT_PROPERTY_TYPE,
    // common routes:
    [ROUTES.DOCUMENTS_UPLOAD]: () =>
      this.getPreviousStepRouteForDocumentUpload(),
    [ROUTES.CONTACT]: () => this.getDocumentUploadPreviousRoute(),
    [ROUTES.SUMMARY]: () => {
      switch (this.formData.selectedFlow) {
        case FLOW.REGISTRATION:
          return ROUTES.CONTACT;
        case FLOW.COMMISSIONING:
          return ROUTES.COMMISSIONING_ELECTRICITY_CONTACT;
        default:
          return ROUTES.CONTACT;
      }
    },
    default: () => this.startingRoute,
  };

  private readonly nextStepRouteHandlers: RouteHandlers = {
    [ROUTES.MODULE_SELECTION]: () => this.evalFlowSelection(),
    [ROUTES.FLOW_SELECTION]: () => ROUTES.ADDRESS,
    [ROUTES.ADDRESS]: () => {
      if (this.otherData.responsibilityCheckFail) {
        return ROUTES.RESPONSIBILITY_CHECK_FAIL;
      }
      switch (this.formData.selectedFlow) {
        case FLOW.COMMISSIONING:
          return ROUTES.COMMISSIONING_ELECTRICITY_OPTIONS;
        case FLOW.REGISTRATION:
        default:
          return this.getRegistrationRoute();
      }
    },
    [ROUTES.RESPONSIBILITY_CHECK_FAIL]: () =>
      this.getResponsibilityCheckFailRoute(),
    [ROUTES.COMMISSIONING_ELECTRICITY_OPTIONS]: () =>
      this.getCommissioningElectricityOptionsRoute(),
    [ROUTES.COMMISSIONING_ELECTRICITY_MAIN_POWER_SUPPLY_TYPE]: () =>
      this.getMainPowerSupplyTypeNextRoute(),
    [ROUTES.COMMISSIONING_ELECTRICITY_PROPERTY_TYPE]: () =>
      ROUTES.COMMISSIONING_ELECTRICITY_INFORMATION,
    [ROUTES.COMMISSIONING_ELECTRICITY_INFORMATION]: () =>
      this.getDocumentUploadNextRoute(),
    [ROUTES.COMMISSIONING_ELECTRICITY_MAIN_POWER_SUPPLY_POWER_INCREASE_DETAILS]:
      () => this.getDocumentUploadNextRoute(),
    [ROUTES.COMMISSIONING_ELECTRICITY_SYSTEMS]: () =>
      this.formData.commissioningElectricitySystems?.meterChanges
        ? ROUTES.COMMISSIONING_ELECTRICITY_MEASUREMENT_DETAILS
        : ROUTES.COMMISSIONING_ELECTRICITY_SIMPLE_MEASUREMENT_DETAILS,
    [ROUTES.COMMISSIONING_ELECTRICITY_SIMPLE_MEASUREMENT_DETAILS]: () =>
      this.getDocumentUploadNextRoute(),
    [ROUTES.COMMISSIONING_ELECTRICITY_MEASUREMENT_DETAILS]: () =>
      this.getDocumentUploadNextRoute(),
    [ROUTES.GAS_OPTIONS]: () => {
      if (this.formData.gasOptions?.gasOption === GAS_OPTION.DETACH) {
        return this.getDocumentUploadNextRoute();
      }
      if (this.formData.gasOptions?.gasOption === GAS_OPTION.CHANGE) {
        if (this.formData.gasOptions.relocate) {
          return ROUTES.GAS_PROPERTY_TYPE;
        } else {
          return this.getDocumentUploadNextRoute();
        }
      } else {
        return ROUTES.GAS_PROPERTY_TYPE;
      }
    },
    [ROUTES.GAS_PROPERTY_TYPE]: () =>
      this.formData.gasOptions?.gasOption === GAS_OPTION.NEW
        ? ROUTES.GAS_POWER_REQUIREMENT
        : this.getDocumentUploadNextRoute(),
    [ROUTES.GAS_POWER_REQUIREMENT]: () => this.getDocumentUploadNextRoute(),
    [ROUTES.WATER_AREA]: () => {
      switch (this.formData.waterArea) {
        case FORM_TYPE.SEWAGE:
          return ROUTES.SEWAGE_OPTIONS;
        case FORM_TYPE.CONSTRUCTION_WATER:
          return ROUTES.CONSTRUCTION_WATER_REQUIREMENTS;
        case FORM_TYPE.WATER:
        default:
          return ROUTES.WATER_OPTIONS;
      }
    },
    [ROUTES.CONSTRUCTION_WATER_REQUIREMENTS]: () =>
      this.getDocumentUploadNextRoute(),
    [ROUTES.SEWAGE_OPTIONS]: () =>
      this.formData.sewageOptions?.sewageOption === SEWAGE_OPTION.DETACH
        ? this.getDocumentUploadNextRoute()
        : ROUTES.WATER_PROPERTY_TYPE,
    [ROUTES.WATER_OPTIONS]: () =>
      this.formData.waterOptions?.waterOption === WATER_OPTION.DETACH
        ? this.getDocumentUploadNextRoute()
        : ROUTES.WATER_PROPERTY_TYPE,
    [ROUTES.WATER_PROPERTY_TYPE]: () => {
      switch (this.formData.waterArea) {
        case FORM_TYPE.SEWAGE:
          return ROUTES.SEWAGE_TYPE;
        case FORM_TYPE.WATER:
        default:
          return this.formData.waterOptions?.waterOption === WATER_OPTION.CHANGE
            ? this.getDocumentUploadNextRoute()
            : ROUTES.WATER_REQUIREMENTS;
      }
    },
    [ROUTES.SEWAGE_TYPE]: () => this.getDocumentUploadNextRoute(),
    [ROUTES.WATER_REQUIREMENTS]: () => this.getDocumentUploadNextRoute(),
    [ROUTES.ELECTRICITY_PRODUCT_SELECTION]: () => {
      switch (this.formData.formType) {
        case FORM_TYPE.PV:
          return ROUTES.PV_TYPE;
        case FORM_TYPE.BALCONY_PV:
          return ROUTES.BALCONY_PV;
        case FORM_TYPE.CHARGING_DEVICE:
          return ROUTES.CHARGING_DEVICE_OPTIONS;
        case FORM_TYPE.GRID_CONNECTION:
          return ROUTES.GRID_CONNECTION_TYPE;
        case FORM_TYPE.HEAT_PUMP:
          return ROUTES.HEAT_PUMP;
        case FORM_TYPE.STORAGE:
          return ROUTES.STORAGE_INFORMATION;
        case FORM_TYPE.OTHER:
          return ROUTES.OTHER;
        default:
          return ROUTES.CONTACT;
      }
    },
    [ROUTES.PV_TYPE]: () =>
      this.formData.pvType === PV_TYPE.CHANGE
        ? ROUTES.CHANGE_PV_DETAILS
        : ROUTES.PV_FACILITY_INFORMATION,
    [ROUTES.CHANGE_PV_DETAILS]: () => this.getDocumentUploadNextRoute(),
    [ROUTES.PV_FACILITY_INFORMATION]: () =>
      this.formData.pvFacilityInformation?.storagePlanned
        ? ROUTES.STORAGE_INFORMATION
        : this.getDocumentUploadNextRoute(),
    [ROUTES.STORAGE_INFORMATION]: () => this.getDocumentUploadNextRoute(),
    [ROUTES.BALCONY_PV]: () =>
      !!this.formData.balconyPV?.registrationRequired
        ? this.getDocumentUploadNextRoute()
        : ROUTES.BALCONY_PV_EXIT,
    [ROUTES.CHARGING_DEVICE_OPTIONS]: () => ROUTES.CHARGING_DEVICE_MORE_OPTIONS,
    [ROUTES.CHARGING_DEVICE_MORE_OPTIONS]: () =>
      ROUTES.CHARGING_DEVICE_PROPERTY_TYPE,
    [ROUTES.CHARGING_DEVICE_PROPERTY_TYPE]: () =>
      this.getDocumentUploadNextRoute(),
    [ROUTES.GRID_CONNECTION_TYPE]: () => {
      switch (this.formData.gridConnectionType) {
        case GRID_CONNECTION_TYPE.NEW:
          return ROUTES.GRID_CONNECTION_PROPERTY_TYPE;
        case GRID_CONNECTION_TYPE.POWER:
          return ROUTES.POWER_CHANGE_DETAILS;
        case GRID_CONNECTION_TYPE.CHANGE:
          return ROUTES.GRID_CONNECTION_CHANGE;
        case GRID_CONNECTION_TYPE.DETACH:
          return this.getDocumentUploadNextRoute();
        case GRID_CONNECTION_TYPE.CONSTRUCTION:
          return ROUTES.CONSTRUCTION_ELECTRICITY_INFORMATION;
        default:
          return ROUTES.CONTACT;
      }
    },
    [ROUTES.GRID_CONNECTION_PROPERTY_TYPE]: () =>
      ROUTES.GRID_CONNECTION_CONSUMER,
    [ROUTES.GRID_CONNECTION_CONSUMER]: () => this.getDocumentUploadNextRoute(),
    [ROUTES.GRID_CONNECTION_CHANGE]: () =>
      this.formData.changeGridConnectionDetails?.totalPowerChanged
        ? ROUTES.POWER_CHANGE_DETAILS
        : this.getDocumentUploadNextRoute(),
    [ROUTES.POWER_CHANGE_DETAILS]: () => this.getDocumentUploadNextRoute(),
    [ROUTES.CONSTRUCTION_ELECTRICITY_INFORMATION]: () =>
      this.getDocumentUploadNextRoute(),
    [ROUTES.HEAT_PUMP]: () => this.getDocumentUploadNextRoute(),
    [ROUTES.OTHER]: () => this.getDocumentUploadNextRoute(),
    [ROUTES.HEAT_OPTIONS]: () =>
      this.formData.heatOptions?.heatOption === HEAT_OPTION.DETACH
        ? this.getDocumentUploadNextRoute()
        : ROUTES.HEAT_PROPERTY_TYPE,
    [ROUTES.HEAT_PROPERTY_TYPE]: () =>
      this.formData.heatOptions?.heatOption === HEAT_OPTION.CHANGE
        ? this.getDocumentUploadNextRoute()
        : ROUTES.HEAT_REQUIREMENTS,
    [ROUTES.HEAT_REQUIREMENTS]: () => this.getDocumentUploadNextRoute(),
    [ROUTES.DOCUMENTS_UPLOAD]: () =>
      this.formData.selectedFlow === FLOW.COMMISSIONING
        ? ROUTES.COMMISSIONING_ELECTRICITY_CONTACT
        : ROUTES.CONTACT,
    [ROUTES.COMMISSIONING_ELECTRICITY_CONTACT]: () => ROUTES.SUMMARY,
    [ROUTES.CONTACT]: () => ROUTES.SUMMARY,
    [ROUTES.SUMMARY]: () => ROUTES.SUCCESS,
    default: () => this.startingRoute,
  };

  private getMainPowerSupplyTypeNextRoute() {
    switch (this.formData.mainPowerSupplyType!) {
      case MainPowerSupplyType.NEW_GRID_CONNECTION:
        return ROUTES.COMMISSIONING_ELECTRICITY_PROPERTY_TYPE;
      case MainPowerSupplyType.POWER_INCREASE:
        return ROUTES.COMMISSIONING_ELECTRICITY_MAIN_POWER_SUPPLY_POWER_INCREASE_DETAILS;
      case MainPowerSupplyType.DECOMMISSIONING:
        return this.getDocumentUploadNextRoute();
      default:
        return ROUTES.COMMISSIONING_ELECTRICITY_MAIN_POWER_SUPPLY_TYPE;
    }
  }

  constructor(
    private router: Router,
    private appStateService: AppStateService,
    private environmentService: EnvironmentService,
    private whiteLabelService: WhiteLabelService
  ) {
    this.whiteLabelService.whiteLabelConfig$.subscribe(
      customerConfigurationDto =>
        (this.customerConfigurationDto = customerConfigurationDto)
    );
  }

  public init(whitelabelExists: boolean): void {
    this.appStateService.observeState().subscribe(({ formData, otherData }) => {
      this.formData = formData;
      this.otherData = otherData;
    });

    this.guardNavigation(whitelabelExists);
  }

  private guardNavigation(whitelabelExists: boolean): void {
    let firstNavigation = true;

    if (this.environmentService.isOnMainDomain) {
      this.navigateToMainDomainPage();
    } else if (!whitelabelExists) {
      this.navigateToErrorPage();
    }
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(navigation => {
        this.currentRoute = this.getKnownRoute(navigation as NavigationEnd);
        if (firstNavigation) {
          firstNavigation = false;
          if (!this.allowedRoutesOnAppStart.includes(this.currentRoute)) {
            this.navigateToFirstStep();
          }
        } else if (
          JSON.stringify(this.formData) ===
            JSON.stringify(this.appStateService.getInitialFormData()) &&
          !this.allowedRoutesWithEmptyState.includes(this.currentRoute)
        ) {
          this.navigateToFirstStep();
        }
      });
  }

  private getKnownRoute(navigationEvent: NavigationEnd): ROUTES | undefined {
    const route = navigationEvent.urlAfterRedirects.startsWith('/')
      ? navigationEvent.urlAfterRedirects.substring(1)
      : navigationEvent.urlAfterRedirects;

    return Object.values(ROUTES)
      .map(r => r.toString())
      .includes(route)
      ? (route as ROUTES)
      : undefined;
  }

  public navigateToFirstStep(): void {
    this.router.navigate([this.startingRoute], { replaceUrl: true });
  }

  public navigateToPreviousStep(): void {
    const newRoute = this.getPreviousStepRoute();

    this.router.navigate([newRoute], this.navigationExtras);
  }

  public navigateToNextStep(): void {
    const newRoute = this.getNextStepRoute();
    this.router.navigate([newRoute], this.navigationExtras);
  }

  public navigateToMainDomainPage(): void {
    this.router.navigate([ROUTES.MAIN_DOMAIN_PAGE], this.navigationExtras);
  }

  public navigateToModuleSelectionPage(): void {
    this.router.navigate([ROUTES.MODULE_SELECTION], this.navigationExtras);
  }

  public navigateToFlowSelectionPage(): void {
    this.router.navigate([this.evalFlowSelection()], this.navigationExtras);
  }

  public navigateToErrorPage(): void {
    this.router.navigate([ROUTES.ERROR], this.navigationExtras);
  }

  private evalEnabledFlowsPreviousRoute() {
    return this.appStateService.isFlowSelectionAvailableForSelectedModule(
      this.formData.selectedModule,
      this.customerConfigurationDto
    )
      ? ROUTES.FLOW_SELECTION
      : ROUTES.MODULE_SELECTION;
  }

  private getPreviousStepRoute(
    givenRoute: ROUTES | undefined = this.currentRoute
  ): ROUTES {
    let handlerResult: RouteHandler | undefined;
    if (givenRoute !== null && givenRoute !== undefined) {
      handlerResult = this.previousStepRouteHandlers[givenRoute];
    }
    return handlerResult
      ? handlerResult()
      : this.previousStepRouteHandlers.default();
  }

  private getPreviousStepRouteForDocumentUploadInWaterModule(): ROUTES {
    switch (this.formData.waterArea) {
      case FORM_TYPE.SEWAGE:
        if (
          this.formData.sewageOptions?.sewageOption === SEWAGE_OPTION.DETACH
        ) {
          return ROUTES.WATER_OPTIONS;
        }
        return ROUTES.SEWAGE_TYPE;

      case FORM_TYPE.CONSTRUCTION_WATER:
        return ROUTES.CONSTRUCTION_WATER_REQUIREMENTS;

      case FORM_TYPE.WATER:
      default:
        switch (this.formData.waterOptions?.waterOption) {
          case WATER_OPTION.DETACH:
            return ROUTES.WATER_OPTIONS;
          case WATER_OPTION.CHANGE:
            return ROUTES.WATER_PROPERTY_TYPE;
          default: // NEW flow
            return ROUTES.WATER_REQUIREMENTS;
        }
    }
  }

  private getPreviousStepRouteForDocumentUploadInGasModule(): ROUTES {
    switch (this.formData.gasOptions?.gasOption) {
      case GAS_OPTION.DETACH:
        return ROUTES.GAS_OPTIONS;
      case GAS_OPTION.CHANGE:
        return this.formData.gasOptions.relocate
          ? ROUTES.GAS_PROPERTY_TYPE
          : ROUTES.GAS_OPTIONS;
      default: // NEW flow
        return ROUTES.GAS_POWER_REQUIREMENT;
    }
  }

  private getPreviousStepRouteForDocumentUploadInHeatModule(): ROUTES {
    switch (this.formData.heatOptions?.heatOption) {
      case HEAT_OPTION.DETACH:
        return ROUTES.HEAT_OPTIONS;
      case HEAT_OPTION.CHANGE:
        return ROUTES.HEAT_PROPERTY_TYPE;
      default: // NEW flow
        return ROUTES.HEAT_REQUIREMENTS;
    }
  }

  private getPreviousStepRouteForDocumentUploadInElectricityModule(): ROUTES {
    switch (this.formData.formType) {
      case FORM_TYPE.PV:
        if (this.formData.pvType === PV_TYPE.CHANGE) {
          return ROUTES.CHANGE_PV_DETAILS;
        } else {
          // NEW flow
          return this.formData.pvFacilityInformation?.storagePlanned
            ? ROUTES.STORAGE_INFORMATION
            : ROUTES.PV_FACILITY_INFORMATION;
        }
      case FORM_TYPE.BALCONY_PV:
        return ROUTES.BALCONY_PV;
      case FORM_TYPE.CHARGING_DEVICE:
        return ROUTES.CHARGING_DEVICE_PROPERTY_TYPE;
      case FORM_TYPE.GRID_CONNECTION:
        switch (this.formData.gridConnectionType) {
          case GRID_CONNECTION_TYPE.NEW:
            return ROUTES.GRID_CONNECTION_CONSUMER;
          case GRID_CONNECTION_TYPE.POWER:
            return ROUTES.POWER_CHANGE_DETAILS;
          case GRID_CONNECTION_TYPE.CHANGE:
            return this.formData.changeGridConnectionDetails?.totalPowerChanged
              ? ROUTES.POWER_CHANGE_DETAILS
              : ROUTES.GRID_CONNECTION_CHANGE;
          case GRID_CONNECTION_TYPE.DETACH:
            return ROUTES.GRID_CONNECTION_TYPE;
          case GRID_CONNECTION_TYPE.CONSTRUCTION:
            return ROUTES.CONSTRUCTION_ELECTRICITY_INFORMATION;
          default:
            return ROUTES.ELECTRICITY_PRODUCT_SELECTION;
        }
      case FORM_TYPE.HEAT_PUMP:
        return ROUTES.HEAT_PUMP;
      case FORM_TYPE.STORAGE:
        return ROUTES.STORAGE_INFORMATION;
      case FORM_TYPE.OTHER:
        return ROUTES.OTHER;
      default:
        return ROUTES.ELECTRICITY_PRODUCT_SELECTION;
    }
  }

  private getPreviousStepRouteForDocumentUpload(): ROUTES {
    switch (this.formData.selectedFlow) {
      case FLOW.COMMISSIONING:
        switch (this.formData.commissioningFlowType) {
          case COMMISSIONING_FLOW_TYPE.CUSTOMER_FACILITY:
            if (this.formData.commissioningElectricitySystems?.meterChanges) {
              return ROUTES.COMMISSIONING_ELECTRICITY_MEASUREMENT_DETAILS;
            } else {
              return ROUTES.COMMISSIONING_ELECTRICITY_SIMPLE_MEASUREMENT_DETAILS;
            }
          case COMMISSIONING_FLOW_TYPE.MAIN_POWER_SUPPLY:
            switch (this.formData.mainPowerSupplyType) {
              case MainPowerSupplyType.NEW_GRID_CONNECTION:
                return ROUTES.COMMISSIONING_ELECTRICITY_INFORMATION;
              case MainPowerSupplyType.POWER_INCREASE:
                return ROUTES.COMMISSIONING_ELECTRICITY_MAIN_POWER_SUPPLY_POWER_INCREASE_DETAILS;
              case MainPowerSupplyType.DECOMMISSIONING:
              default:
                return ROUTES.COMMISSIONING_ELECTRICITY_MAIN_POWER_SUPPLY_TYPE;
            }
          default:
            return ROUTES.COMMISSIONING_ELECTRICITY_INFORMATION;
        }
      case FLOW.REGISTRATION:
      default:
        switch (this.formData.selectedModule) {
          case FLOW_MODULE.GAS:
            return this.getPreviousStepRouteForDocumentUploadInGasModule();
          case FLOW_MODULE.WATER:
            return this.getPreviousStepRouteForDocumentUploadInWaterModule();
          case FLOW_MODULE.HEAT:
            return this.getPreviousStepRouteForDocumentUploadInHeatModule();
          case FLOW_MODULE.ELECTRICITY:
          default:
            return this.getPreviousStepRouteForDocumentUploadInElectricityModule();
        }
    }
  }

  private getNextStepRoute(
    givenRoute: ROUTES | undefined = this.currentRoute
  ): ROUTES {
    let handlerResult: RouteHandler | undefined;
    if (givenRoute !== null && givenRoute !== undefined) {
      handlerResult = this.nextStepRouteHandlers[givenRoute];
    }
    return handlerResult
      ? handlerResult()
      : this.nextStepRouteHandlers.default();
  }

  private getRegistrationRoute(): ROUTES {
    switch (this.formData.selectedModule) {
      case FLOW_MODULE.WATER:
        return ROUTES.WATER_AREA;
      case FLOW_MODULE.HEAT:
        return ROUTES.HEAT_OPTIONS;
      case FLOW_MODULE.GAS:
        return ROUTES.GAS_OPTIONS;
      case FLOW_MODULE.ELECTRICITY:
      default:
        return ROUTES.ELECTRICITY_PRODUCT_SELECTION;
    }
  }

  private getResponsibilityCheckFailRoute(): ROUTES {
    switch (this.formData.selectedFlow) {
      case FLOW.COMMISSIONING:
        return ROUTES.COMMISSIONING_ELECTRICITY_OPTIONS;
      case FLOW.REGISTRATION:
      default:
        return this.getRegistrationRoute();
    }
  }

  private getCommissioningElectricityOptionsRoute(): ROUTES {
    switch (this.formData.commissioningFlowType) {
      case COMMISSIONING_FLOW_TYPE.MAIN_POWER_SUPPLY:
        return ROUTES.COMMISSIONING_ELECTRICITY_MAIN_POWER_SUPPLY_TYPE;
      case COMMISSIONING_FLOW_TYPE.CUSTOMER_FACILITY:
      default:
        return ROUTES.COMMISSIONING_ELECTRICITY_SYSTEMS;
    }
  }

  private evalFlowSelection() {
    if (
      this.appStateService.isFlowSelectionAvailableForSelectedModule(
        this.formData.selectedModule,
        this.customerConfigurationDto
      )
    ) {
      return ROUTES.FLOW_SELECTION;
    } else {
      // if only 1 flow is available, then set it as selectedFlow as flow selection step is skipped
      this.appStateService.evaluateFlowAndUpdateModuleInState(
        this.formData.selectedModule,
        this.customerConfigurationDto
      );
      return ROUTES.ADDRESS;
    }
  }

  private getDocumentUploadNextRoute(): ROUTES {
    const documentsUploadEnabled =
      this.customerConfigurationDto?.documentsUploadEnabled;
    if (documentsUploadEnabled) {
      return ROUTES.DOCUMENTS_UPLOAD;
    } else {
      return this.getNextStepRoute(ROUTES.DOCUMENTS_UPLOAD);
    }
  }

  private getDocumentUploadPreviousRoute(): ROUTES {
    const documentsUploadEnabled =
      this.customerConfigurationDto?.documentsUploadEnabled;
    if (documentsUploadEnabled) {
      return ROUTES.DOCUMENTS_UPLOAD;
    } else {
      return this.getPreviousStepRoute(ROUTES.DOCUMENTS_UPLOAD);
    }
  }
}
