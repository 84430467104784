<div class="hint-wrapper" [ngClass]="showOutline ? 'outline' : ''">
  <mat-icon
    aria-hidden="false"
    aria-label="hint information icon"
    [fontIcon]="warning ? 'warning_amber' : 'sms_fail'"
    class="info-icon material-icons-outlined">
  </mat-icon>
  <div>
    <ng-content></ng-content>
  </div>
</div>
