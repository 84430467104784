@if (measurementDetail) {
  <app-summary-section-field
    label="COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.CHANGE_TYPE.SUBHEADING"
    [value]="
      'COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.CHANGE_TYPE.TYPES.' +
        measurementDetail.changeType | translate
    ">
  </app-summary-section-field>
  @if (
    measurementDetail.meterNumber &&
    measurementDetail.changeType !== MEASUREMENT_DEVICE_CHANGE_TYPE.INSTALLATION
  ) {
    <app-summary-section-field
      label="COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.DEVICE_INFORMATION.METER_NUMBER"
      [value]="measurementDetail.meterNumber">
    </app-summary-section-field>
  }
  <app-summary-section-field
    label="COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.MEASUREMENT_TYPE.SUBHEADING"
    [value]="
      (
        measurementDetail.measurementTypes
        | mapWithPrefix
          : 'COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.MEASUREMENT_TYPE.TYPES.'
        | mapTranslate
      ).join(', ')
    ">
  </app-summary-section-field>
  <app-summary-section-field
    label="COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.METER_LOCATION.SUBHEADING"
    [value]="
      'COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.METER_LOCATION.TYPES.' +
        measurementDetail.meterLocation.locationType | translate
    ">
  </app-summary-section-field>
  @if (measurementDetail.meterLocation.locationType === METER_LOCATION.OTHER) {
    <app-summary-section-field
      label="COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.METER_LOCATION.TYPES.OTHER"
      [value]="measurementDetail.meterLocation.otherLocation">
    </app-summary-section-field>
  }
  <app-summary-section-field
    label="COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.FASTENING_TYPE.SUBHEADING"
    [value]="
      'COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.FASTENING_TYPE.TYPES.' +
        measurementDetail.fasteningType | translate
    ">
  </app-summary-section-field>
  <app-summary-section-field
    label="COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.CONVERTER_SIZE"
    [value]="measurementDetail.converterSize">
  </app-summary-section-field>
  <app-summary-section-field
    label="COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.MEASURING_POINT_OPERATOR.TYPES.BASIC_RESPONSIBLE"
    [value]="
      measurementDetail.measuringPointOperator.basicResponsible
        | booleanTranslateKey
        | translate
    ">
  </app-summary-section-field>
  @if (!measurementDetail.measuringPointOperator.basicResponsible) {
    <app-summary-section-field
      label="COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.MEASURING_POINT_OPERATOR.TYPES.OTHER_OPERATOR"
      [value]="measurementDetail.measuringPointOperator.otherOperator">
    </app-summary-section-field>
  }
}
