import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ROUTES } from '@app/models/routes.enum';
import { GridConnectionChangeComponent } from '@app/modules/electricity/grid-connection/grid-connection-change/grid-connection-change.component';
import { PowerChangeDetailsComponent } from '@app/modules/electricity/grid-connection/power-change-details/power-change-details.component';
import { AddressFormComponent } from './modules/common-steps/address-form/address-form.component';
import { ContactComponent } from './modules/common-steps/contact/contact.component';
import { DocumentsUploadComponent } from './modules/common-steps/documents-upload/documents-upload.component';
import { ErrorComponent } from './modules/common-steps/error/error.component';
import { MainDomainPageComponent } from './modules/common-steps/main-domain-page/main-domain-page.component';
import { ModuleSelectionComponent } from './modules/common-steps/module-selection/module-selection.component';
import { ResponsibilityCheckFailComponent } from './modules/common-steps/responsibility-check-fail/responsibility-check-fail.component';
import { SuccessComponent } from './modules/common-steps/success/success.component';
import { SummaryComponent } from './modules/common-steps/summary/summary.component';
import { BalconyPvComponent } from './modules/electricity/balcony-pv/balcony-pv.component';
import { ChargingDeviceMoreOptionsComponent } from './modules/electricity/charging-device/charging-device-more-options/charging-device-more-options.component';
import { ChargingDeviceOptionsComponent } from './modules/electricity/charging-device/charging-device-options/charging-device-options.component';
import { ChargingDevicePropertyTypeComponent } from './modules/electricity/charging-device/charging-device-property-type/charging-device-property-type.component';
import { GridConnectionConsumerComponent } from './modules/electricity/grid-connection/grid-connection-consumer/grid-connection-consumer.component';
import { GridConnectionPropertyTypeComponent } from './modules/electricity/grid-connection/grid-connection-property-type/grid-connection-property-type.component';
import { GridConnectionTypeComponent } from './modules/electricity/grid-connection/grid-connection-type/grid-connection-type.component';
import { HeatPumpComponent } from './modules/electricity/heat-pump/heat-pump.component';
import { ProductSelectionComponent } from './modules/electricity/product-selection/product-selection.component';
import { PvFacilityInformationComponent } from './modules/electricity/pv-system/pv-facility-information/pv-facility-information.component';
import { GasOptionsComponent } from './modules/gas/gas-options/gas-options.component';
import { GasPowerRequirementComponent } from './modules/gas/gas-power-requirement/gas-power-requirement.component';
import { GasPropertyTypeComponent } from './modules/gas/gas-property-type/gas-property-type.component';
import { WaterOptionsComponent } from './modules/water/water-options/water-options.component';
import { WaterPropertyTypeComponent } from './modules/water/water-property-type/water-property-type.component';
import { WaterRequirementsComponent } from './modules/water/water-requirements/water-requirements.component';
import { StorageInformationComponent } from './modules/electricity/storage-information/storage-information.component';
import { OtherProductComponent } from './modules/electricity/other-product/other-product.component';
import { HeatOptionsComponent } from './modules/heat/heat-options/heat-options.component';
import { HeatPropertyTypeComponent } from './modules/heat/heat-property-type/heat-property-type.component';
import { HeatRequirementsComponent } from './modules/heat/heat-requirements/heat-requirements.component';
import { FlowSelectionComponent } from './modules/common-steps/flow-selection/flow-selection.component';
import { CommissioningElectricityOptionsComponent } from './modules/commissioning-electricity/commissioning-electricity-options/commissioning-electricity-options.component';
import { CommissioningElectricityContactComponent } from './modules/commissioning-electricity/commissioning-electricity-contact/commissioning-electricity-contact.component';
import { CommissioningElectricityPropertyTypeComponent } from '@app/modules/commissioning-electricity/main-power-supply/commissioning-electricity-property-type/commissioning-electricity-property-type.component';
import { CommissioningElectricityInformationComponent } from '@app/modules/commissioning-electricity/main-power-supply/commissioning-electricity-information/commissioning-electricity-information.component';
import { CommissioningElectricitySystemsComponent } from '@app/modules/commissioning-electricity/customer-facility/commissioning-electricity-systems/commissioning-electricity-systems.component';
import { CommissioningElectricitySimpleMeasurementDetailsComponent } from '@app/modules/commissioning-electricity/customer-facility/commissioning-electricity-simple-measurement-details/commissioning-electricity-simple-measurement-details.component';
import { CommissioningElectricityMeasurementDetailsComponent } from '@app/modules/commissioning-electricity/commissioning-electricity-measurement-details/commissioning-electricity-measurement-details.component';
import { ConstructionGridConnectionDetailsComponent } from './modules/electricity/grid-connection/construction-grid-connection-details/construction-grid-connection-details.component';
import { WaterAreaComponent } from './modules/water/water-area/water-area.component';
import { SewageOptionsComponent } from './modules/water/sewage-options/sewage-options.component';
import { SewageTypeComponent } from './modules/water/sewage-type/sewage-type.component';
import { ConstructionWaterRequirementsComponent } from './modules/water/construction-water-requirements/construction-water-requirements.component';
import { PvTypeComponent } from './modules/electricity/pv-system/pv-type/pv-type.component';
import { ChangePVDetailsComponent } from './modules/electricity/pv-system/change-pv-details/change-pv-details.component';
import { MainPowerSupplyTypeComponent } from '@app/modules/commissioning-electricity/main-power-supply/main-power-supply-type/main-power-supply-type.component';
import { PowerIncreaseDetailsComponent } from '@app/modules/commissioning-electricity/main-power-supply/power-increase-details/power-increase-details.component';
import { BalconyPvExitComponent } from '@app/modules/electricity/balcony-pv-exit/balcony-pv-exit.component';

const routes: Routes = [
  {
    path: '',
    component: ModuleSelectionComponent,
  },
  {
    path: ROUTES.MODULE_SELECTION,
    component: ModuleSelectionComponent,
  },
  {
    path: ROUTES.FLOW_SELECTION,
    component: FlowSelectionComponent,
  },
  {
    path: ROUTES.MAIN_DOMAIN_PAGE,
    component: MainDomainPageComponent,
  },
  {
    path: ROUTES.ADDRESS,
    component: AddressFormComponent,
  },
  {
    path: ROUTES.RESPONSIBILITY_CHECK_FAIL,
    component: ResponsibilityCheckFailComponent,
  },
  // commissioning electricity steps routes
  {
    path: ROUTES.COMMISSIONING_ELECTRICITY_OPTIONS,
    component: CommissioningElectricityOptionsComponent,
  },
  // commissioning - main power supply
  {
    path: ROUTES.COMMISSIONING_ELECTRICITY_MAIN_POWER_SUPPLY_TYPE,
    component: MainPowerSupplyTypeComponent,
  },
  {
    path: ROUTES.COMMISSIONING_ELECTRICITY_PROPERTY_TYPE,
    component: CommissioningElectricityPropertyTypeComponent,
  },
  {
    path: ROUTES.COMMISSIONING_ELECTRICITY_INFORMATION,
    component: CommissioningElectricityInformationComponent,
  },
  {
    path: ROUTES.COMMISSIONING_ELECTRICITY_MAIN_POWER_SUPPLY_POWER_INCREASE_DETAILS,
    component: PowerIncreaseDetailsComponent,
  },
  // commissioning - customer facility
  {
    path: ROUTES.COMMISSIONING_ELECTRICITY_SYSTEMS,
    component: CommissioningElectricitySystemsComponent,
  },
  {
    path: ROUTES.COMMISSIONING_ELECTRICITY_SIMPLE_MEASUREMENT_DETAILS,
    component: CommissioningElectricitySimpleMeasurementDetailsComponent,
  },
  {
    path: ROUTES.COMMISSIONING_ELECTRICITY_MEASUREMENT_DETAILS,
    component: CommissioningElectricityMeasurementDetailsComponent,
  },
  // commissioning - shared electricity routes
  {
    path: ROUTES.COMMISSIONING_ELECTRICITY_CONTACT,
    component: CommissioningElectricityContactComponent,
  },
  // gas steps routes
  {
    path: ROUTES.GAS_OPTIONS,
    component: GasOptionsComponent,
  },
  {
    path: ROUTES.GAS_PROPERTY_TYPE,
    component: GasPropertyTypeComponent,
  },
  {
    path: ROUTES.GAS_POWER_REQUIREMENT,
    component: GasPowerRequirementComponent,
  },
  // water routes
  {
    path: ROUTES.WATER_AREA,
    component: WaterAreaComponent,
  },
  {
    path: ROUTES.CONSTRUCTION_WATER_REQUIREMENTS,
    component: ConstructionWaterRequirementsComponent,
  },
  {
    path: ROUTES.SEWAGE_OPTIONS,
    component: SewageOptionsComponent,
  },
  {
    path: ROUTES.SEWAGE_TYPE,
    component: SewageTypeComponent,
  },
  {
    path: ROUTES.WATER_OPTIONS,
    component: WaterOptionsComponent,
  },
  {
    path: ROUTES.WATER_PROPERTY_TYPE,
    component: WaterPropertyTypeComponent,
  },
  {
    path: ROUTES.WATER_REQUIREMENTS,
    component: WaterRequirementsComponent,
  },
  {
    path: ROUTES.ELECTRICITY_PRODUCT_SELECTION,
    component: ProductSelectionComponent,
  },
  {
    path: ROUTES.PV_TYPE,
    component: PvTypeComponent,
  },
  {
    path: ROUTES.PV_FACILITY_INFORMATION,
    component: PvFacilityInformationComponent,
  },
  {
    path: ROUTES.CHANGE_PV_DETAILS,
    component: ChangePVDetailsComponent,
  },
  {
    path: ROUTES.STORAGE_INFORMATION,
    component: StorageInformationComponent,
  },
  {
    path: ROUTES.CHARGING_DEVICE_OPTIONS,
    component: ChargingDeviceOptionsComponent,
  },
  {
    path: ROUTES.CHARGING_DEVICE_MORE_OPTIONS,
    component: ChargingDeviceMoreOptionsComponent,
  },
  {
    path: ROUTES.CHARGING_DEVICE_PROPERTY_TYPE,
    component: ChargingDevicePropertyTypeComponent,
  },
  {
    path: ROUTES.BALCONY_PV,
    component: BalconyPvComponent,
  },
  {
    path: ROUTES.BALCONY_PV_EXIT,
    component: BalconyPvExitComponent,
  },
  {
    path: ROUTES.GRID_CONNECTION_TYPE,
    component: GridConnectionTypeComponent,
  },
  {
    path: ROUTES.GRID_CONNECTION_PROPERTY_TYPE,
    component: GridConnectionPropertyTypeComponent,
  },
  {
    path: ROUTES.GRID_CONNECTION_CONSUMER,
    component: GridConnectionConsumerComponent,
  },
  {
    path: ROUTES.GRID_CONNECTION_CHANGE,
    component: GridConnectionChangeComponent,
  },
  {
    path: ROUTES.POWER_CHANGE_DETAILS,
    component: PowerChangeDetailsComponent,
  },
  {
    path: ROUTES.CONSTRUCTION_ELECTRICITY_INFORMATION,
    component: ConstructionGridConnectionDetailsComponent,
  },
  {
    path: ROUTES.HEAT_PUMP,
    component: HeatPumpComponent,
  },
  {
    path: ROUTES.OTHER,
    component: OtherProductComponent,
  },
  {
    path: ROUTES.HEAT_OPTIONS,
    component: HeatOptionsComponent,
  },
  {
    path: ROUTES.HEAT_PROPERTY_TYPE,
    component: HeatPropertyTypeComponent,
  },
  {
    path: ROUTES.HEAT_REQUIREMENTS,
    component: HeatRequirementsComponent,
  },
  {
    path: ROUTES.DOCUMENTS_UPLOAD,
    component: DocumentsUploadComponent,
  },
  {
    path: ROUTES.CONTACT,
    component: ContactComponent,
  },
  {
    path: ROUTES.SUMMARY,
    component: SummaryComponent,
  },
  {
    path: ROUTES.SUCCESS,
    component: SuccessComponent,
  },
  {
    path: ROUTES.ERROR,
    component: ErrorComponent,
  },
  { path: '**', redirectTo: ROUTES.MODULE_SELECTION },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
