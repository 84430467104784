<app-form-card
  titleIcon="electric_bolt"
  cardTitle="HEAT_PUMP.HEADING"
  description="HEAT_PUMP.DESCRIPTION"
  dataTestId="heat-pump-information-heading">
  <form [formGroup]="heatPumpForm">
    <div class="space"></div>
    <h2 class="tooltip-heading-label">
      {{ 'HEAT_PUMP.SYSTEM_BASIC_INFORMATION' | translate }}
    </h2>
    <div class="input-container">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{ 'HEAT_PUMP.SYSTEM_MANUFACTURER' | translate }}</mat-label>
        <input
          matInput
          type="text"
          name="systemManufacturer"
          formControlName="systemManufacturer" />
        <mat-error appErrorMessages="systemManufacturer"></mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{ 'HEAT_PUMP.SYSTEM_TYPE' | translate }}</mat-label>
        <input
          matInput
          type="text"
          name="systemType"
          formControlName="systemType" />
        <mat-error appErrorMessages="systemType"></mat-error>
      </mat-form-field>
    </div>

    <div class="space"></div>
    <h2 class="tooltip-heading-label">
      {{ 'HEAT_PUMP.SYSTEM_POWER' | translate }}
      <app-info-tooltip tooltipText="HEAT_PUMP.SYSTEM_POWER_TOOLTIP">
      </app-info-tooltip>
    </h2>
    <div class="input-container">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{ 'HEAT_PUMP.SYSTEM_POWER_LABEL' | translate }}</mat-label>
        <input
          matInput
          type="number"
          name="systemPower"
          formControlName="systemPower"
          min="0"
          data-testid="system-power" />
        <mat-error appErrorMessages="systemPower"></mat-error>
      </mat-form-field>
    </div>

    <div class="space"></div>

    <h2>
      <label id="meter-required-radio-group-label">
        {{ ('HEAT_PUMP.METER_REQUIRED' | translate) + ' *' }}</label
      >
    </h2>
    <mat-radio-group
      color="primary"
      formControlName="meterRequired"
      aria-labelledby="meter-required-radio-group-label"
      class="input-container">
      <div>
        <mat-radio-button [value]="true" data-testid="meter-required">
          {{ 'COMMON.YES' | translate }}
        </mat-radio-button>
        <mat-radio-button [value]="false" data-testid="no-meter-required">
          {{ 'COMMON.NO' | translate }}
        </mat-radio-button>
      </div>
      <mat-error appErrorMessages="meterRequired" [hideError]="!allTouched">
      </mat-error>
    </mat-radio-group>

    @if ((heatPumpForm | formLookup: 'meterRequired') === false) {
      <div>
        <h2 class="tooltip-heading-label">
          {{ 'HEAT_PUMP.METER_NUMBER' | translate }}
          <app-info-tooltip tooltipText="HEAT_PUMP.METER_NUMBER_TOOLTIP">
          </app-info-tooltip>
        </h2>
        <div class="input-container">
          <mat-form-field appearance="outline" class="input-field">
            <mat-label>{{ 'HEAT_PUMP.METER_NUMBER' | translate }}</mat-label>
            <input
              matInput
              name="meterNumber"
              formControlName="meterNumber"
              data-testid="meter-number" />
            <mat-error appErrorMessages="meterNumber"></mat-error>
          </mat-form-field>
        </div>
        <div class="space"></div>
      </div>
    }

    <h2>
      <label id="power-increase-radio-group-label">
        {{ ('HEAT_PUMP.CONNECTION_POWER_INCREASE' | translate) + ' *' }}
      </label>
    </h2>
    <mat-radio-group
      color="primary"
      formControlName="connectionPowerIncrease"
      aria-labelledby="power-increase-radio-group-label"
      class="input-container">
      <div>
        <mat-radio-button
          [value]="true"
          data-testid="connection-power-increase">
          {{ 'COMMON.YES' | translate }}
        </mat-radio-button>
        <mat-radio-button
          [value]="false"
          data-testid="no-connection-power-increase">
          {{ 'COMMON.NO' | translate }}
        </mat-radio-button>
      </div>
      <mat-error
        appErrorMessages="connectionPowerIncrease"
        [hideError]="!allTouched">
      </mat-error>
    </mat-radio-group>

    @if (heatPumpForm | formLookup: 'connectionPowerIncrease') {
      <div>
        <h2 class="tooltip-heading-label">
          {{ 'HEAT_PUMP.CURRENT_POWER_DEMAND' | translate }}
          <app-info-tooltip
            tooltipText="HEAT_PUMP.CURRENT_POWER_DEMAND_TOOLTIP">
          </app-info-tooltip>
        </h2>
        <div class="input-container">
          <mat-form-field appearance="outline" class="input-field">
            <mat-label>{{
              'HEAT_PUMP.CURRENT_POWER_DEMAND_LABEL' | translate
            }}</mat-label>
            <input
              matInput
              type="number"
              name="currentPowerDemand"
              formControlName="currentPowerDemand"
              min="0"
              data-testid="current-power-demand" />
            <mat-error appErrorMessages="currentPowerDemand"></mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="input-field">
            <mat-label>{{
              'HEAT_PUMP.PLANNED_POWER_DEMAND' | translate
            }}</mat-label>
            <input
              matInput
              type="number"
              name="plannedPowerDemand"
              formControlName="plannedPowerDemand"
              min="0"
              data-testid="planned-power-demand" />
            <mat-error appErrorMessages="plannedPowerDemand"></mat-error>
          </mat-form-field>
        </div>
      </div>
    }

    <h2 class="tooltip-heading-label">
      {{ ('SHARED.CONTROLLABLE_POWER.LABEL' | translate) + ' *' }}
      <app-info-tooltip tooltipText="SHARED.CONTROLLABLE_POWER.TOOLTIP">
      </app-info-tooltip>
    </h2>

    <mat-radio-group
      color="primary"
      formControlName="controllablePower"
      class="input-container">
      <div>
        <mat-radio-button
          [value]="controllablePower.CONTROLLABLE_CONSUMPTION_DEVICES"
          data-testid="controllable-consumption-devices">
          {{
            'SHARED.CONTROLLABLE_POWER.OPTION.CONTROLLABLE_CONSUMPTION_DEVICES'
              | translate
          }}
        </mat-radio-button>
        <mat-radio-button
          [value]="controllablePower.ENERGY_MANAGEMENT_SYSTEM"
          data-testid="energy-management-system">
          {{
            'SHARED.CONTROLLABLE_POWER.OPTION.ENERGY_MANAGEMENT_SYSTEM'
              | translate
          }}
        </mat-radio-button>
        <mat-radio-button
          [value]="controllablePower.NO"
          data-testid="no-controllable-power">
          {{ 'SHARED.CONTROLLABLE_POWER.OPTION.NO' | translate }}
        </mat-radio-button>
      </div>
      <mat-error appErrorMessages="controllablePower" [hideError]="!allTouched">
      </mat-error>
    </mat-radio-group>

    <h2>
      {{ 'HEAT_PUMP.ANYTHING_ELSE' | translate }}
    </h2>
    <div class="input-container">
      <mat-form-field appearance="outline" class="textarea-field">
        <mat-label>{{ 'HEAT_PUMP.NOTES' | translate }}</mat-label>
        <textarea
          #notes
          matInput
          cols="50"
          rows="6"
          [maxlength]="maxLength"
          formControlName="notes"></textarea>
        <mat-hint align="end"
          >{{ notes.value.length }} / {{ maxLength }}</mat-hint
        >
        <mat-error appErrorMessages="notes"></mat-error>
      </mat-form-field>
    </div>
  </form>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: heatPumpForm.invalid,
    }"
    (previous)="previous()"
    (next)="next()"></app-form-navigation>
</app-form-card>
