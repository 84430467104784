@if (powerIncreaseDetails) {
  <app-summary-section
    heading="COMMISSIONING_ELECTRICITY.MAIN_POWER_SUPPLY.POWER_INCREASE.HEADING"
    [stepRoute]="powerIncreaseDetailsRoute">
    @if (powerIncreaseDetails.currentPowerDemand) {
      <app-summary-section-field
        label="POWER_CHANGE_DETAILS.CURRENT_POWER_DEMAND"
        [value]="powerIncreaseDetails.currentPowerDemand">
      </app-summary-section-field>
    }
    @if (powerIncreaseDetails.plannedPowerDemand) {
      <app-summary-section-field
        label="POWER_CHANGE_DETAILS.PLANNED_POWER_DEMAND"
        [value]="powerIncreaseDetails.plannedPowerDemand">
      </app-summary-section-field>
    }
  </app-summary-section>
}
