<app-form-card svgIcon="gas" cardTitle="WATER.SEWAGE_TYPE.HEADING">
  <div class="space"></div>

  <form>
    <h2 class="full-width">
      {{ 'WATER.SEWAGE_TYPE.SEWAGE_TYPE_QUESTION' | translate }}
    </h2>

    <div class="space"></div>

    <div class="input-container">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          'WATER.SEWAGE_TYPE.SEWAGE_TYPE_LABEL' | translate
        }}</mat-label>
        <mat-select [formControl]="sewageTypeControl">
          @for (sewageType of sewageTypeList; track sewageType) {
            <mat-option [value]="sewageType">
              {{ 'WATER.SEWAGE_TYPE.SEWAGE_TYPES.' + sewageType | translate }}
            </mat-option>
          }
        </mat-select>
        @if (sewageTypeControl.invalid && allTouched) {
          <mat-error>{{
            'VALIDATION.REQUIRED_SELECTION' | translate
          }}</mat-error>
        }
      </mat-form-field>
    </div>
  </form>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: sewageTypeControl.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
