<app-form-card
  titleIcon="electric_bolt"
  cardTitle="COMMISSIONING_ELECTRICITY.SYSTEM.HEADING"
  description=""
  dataTestId="commissioning-electricity-systems-heading">
  <h2 class="full-width">
    {{ 'COMMISSIONING_ELECTRICITY.SYSTEM.DESCRIPTION' | translate }}
  </h2>
  <div class="space"></div>
  <form [formGroup]="systemForm">
    <h2>
      <label id="measurement-setup-change-radio-group-label" appRequiredSuffix>
        {{
          'COMMISSIONING_ELECTRICITY.SYSTEM.METER_CHANGES' | translate
        }}</label
      >
    </h2>

    <mat-radio-group
      color="primary"
      formControlName="meterChanges"
      aria-labelledby="measurement-setup-change-radio-group-label"
      class="input-container">
      <div>
        <mat-radio-button [value]="true" data-testid="meter-changes">
          {{ 'COMMON.YES' | translate }}
        </mat-radio-button>
        <mat-radio-button [value]="false" data-testid="no-meter-changes">
          {{ 'COMMON.NO' | translate }}
        </mat-radio-button>
      </div>
      <mat-error appErrorMessages="meterChanges" [hideError]="!allTouched">
      </mat-error>
    </mat-radio-group>

    <div>
      <h2 class="full-width">
        {{ 'COMMISSIONING_ELECTRICITY.SYSTEM.SYSTEM_DESCRIPTION' | translate }}
      </h2>
      @for (system of systemList; track system) {
        <div class="single-column-layout half" [formGroupName]="system.type">
          <div class="two-column-layout-item checkbox-with-tooltip">
            <app-selection-card
              [icon]="system.icon"
              [svgIcon]="system.svgIcon"
              [label]="system.label"
              [selected]="systemForm | formLookup: system.type : 'selected'"
              [showCheckbox]="true"
              [vertical]="false"
              (click)="toggleCardSelection(system.type)"
              [attr.data-testid]="system.type">
              <mat-checkbox color="primary" formControlName="selected">
              </mat-checkbox>
            </app-selection-card>
            <app-info-tooltip
              [tooltipText]="
                'COMMISSIONING_ELECTRICITY.CUSTOMER_FACILITY.TOOLTIP.' +
                system.type
              ">
            </app-info-tooltip>
          </div>
          @if (systemForm | formLookup: system.type : 'selected') {
            <div class="single-column-layout">
              @if (otherType !== system.type) {
                <mat-form-field appearance="outline" class="input-field">
                  <mat-label>{{
                    'COMMISSIONING_ELECTRICITY.SYSTEM.SYSTEM_POWER' | translate
                  }}</mat-label>
                  <input
                    matInput
                    type="number"
                    name="systemPower"
                    formControlName="systemPower"
                    min="0"
                    [attr.data-testid]="system.type + '-system-power'" />
                  <mat-error appErrorMessages="systemPower"></mat-error>
                </mat-form-field>
              }
              @if (otherType === system.type) {
                <mat-form-field appearance="outline" class="input-field">
                  <mat-label>{{
                    'COMMISSIONING_ELECTRICITY.SYSTEM.SYSTEM_TYPE' | translate
                  }}</mat-label>
                  <input
                    matInput
                    type="text"
                    name="systemType"
                    formControlName="systemType"
                    [attr.data-testid]="system.type + '-system-type'" />
                  <mat-error appErrorMessages="systemType"></mat-error>
                </mat-form-field>
              }
              <mat-form-field appearance="outline" class="input-field">
                <mat-label>{{
                  'COMMISSIONING_ELECTRICITY.SYSTEM.SYSTEM_COUNT' | translate
                }}</mat-label>
                <input
                  matInput
                  type="number"
                  name="systemCount"
                  formControlName="systemCount"
                  min="0"
                  [attr.data-testid]="system.type + '-system-count'" />
                <mat-error appErrorMessages="systemCount"></mat-error>
              </mat-form-field>
              @if (otherType === system.type) {
                <mat-form-field appearance="outline" class="textarea-field">
                  <mat-label>{{
                    'COMMISSIONING_ELECTRICITY.SYSTEM.NOTES' | translate
                  }}</mat-label>
                  <textarea
                    #notes
                    matInput
                    cols="50"
                    rows="6"
                    [maxlength]="maxLength"
                    formControlName="notes"
                    [attr.data-testid]="system.type + '-notes'"></textarea>
                  <mat-hint align="end"
                    >{{ notes.value.length }} / {{ maxLength }}</mat-hint
                  >
                  <mat-error appErrorMessages="notes"></mat-error>
                </mat-form-field>
              }
            </div>
          }
        </div>
      }
    </div>

    @if (allTouched && electricitySystemsRequired && !oneOrMoreSystemSelected) {
      <div>
        <mat-error>{{ 'VALIDATION.REQUIRED_SELECTION' | translate }}</mat-error>
      </div>
    }
  </form>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled:
        allTouched &&
        (systemForm.invalid ||
          (electricitySystemsRequired && !oneOrMoreSystemSelected)),
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
