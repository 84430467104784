@if (simpleMeasurementDetails) {
  <app-summary-section
    heading="COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.HEADING"
    [stepRoute]="simpleMeasurementDetailsRoute">
    <app-summary-section-field
      label="COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.DEVICE_INFORMATION.METER_NUMBER"
      [value]="simpleMeasurementDetails.meterNumbers.join(', ')">
    </app-summary-section-field>
  </app-summary-section>
}
