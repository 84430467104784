<app-form-card
  titleIcon="contact_support"
  cardTitle="ADDRESS.HEADING"
  description="ADDRESS.DESCRIPTION"
  dataTestId="address-heading">
  @if (loading) {
    <app-loading-spinner></app-loading-spinner>
  }
  <form [formGroup]="addressForm">
    <h2>{{ 'ADDRESS.SUBHEADING' | translate }}</h2>
    <div class="input-container">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{ 'ADDRESS.FORM.ZIP_CODE' | translate }}</mat-label>
        <input
          matInput
          type="text"
          name="zipCode"
          formControlName="zipCode"
          data-testid="zip-code" />
        <mat-error appErrorMessages="zipCode"></mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{ 'ADDRESS.FORM.CITY' | translate }}</mat-label>
        <input
          matInput
          type="text"
          name="city"
          data-testid="city"
          formControlName="city" />
        <mat-error appErrorMessages="city"></mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{ 'ADDRESS.FORM.STREET' | translate }}</mat-label>
        <input
          matInput
          type="text"
          name="street"
          data-testid="street"
          formControlName="street" />
        <mat-error appErrorMessages="street"></mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{ 'ADDRESS.FORM.STREET_NUMBER' | translate }}</mat-label>
        <input
          matInput
          formControlName="streetNumber"
          name="streetNumber"
          data-testid="street-number" />
        <mat-error appErrorMessages="streetNumber"></mat-error>
      </mat-form-field>
    </div>

    <p class="street-not-listed-selection">
      <mat-checkbox formControlName="streetNotListed" color="primary">{{
        'ADDRESS.FORM.STREET_NOT_LISTED' | translate
      }}</mat-checkbox>
      <app-info-tooltip tooltipText="ADDRESS.FORM.STREET_NOT_LISTED_TOOLTIP">
      </app-info-tooltip>
    </p>

    @if (addressForm | formLookup: 'streetNotListed') {
      <div formGroupName="additionalAddressData" class="input-container">
        <mat-form-field appearance="outline" class="input-field">
          <mat-label>{{ 'ADDRESS.FORM.DISTRICT' | translate }}</mat-label>
          <input
            matInput
            type="text"
            name="district"
            formControlName="district" />
          <mat-error appErrorMessages="district"></mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="nested-input-field">
          <mat-label>{{ 'ADDRESS.FORM.PARCEL' | translate }}</mat-label>
          <input matInput type="text" name="parcel" formControlName="parcel" />
          <mat-error appErrorMessages="parcel"></mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="nested-input-field">
          <mat-label>{{ 'ADDRESS.FORM.PARCEL_NUMBER' | translate }}</mat-label>
          <input
            matInput
            type="text"
            name="parcelNumber"
            formControlName="parcelNumber" />
          <mat-error appErrorMessages="parcelNumber"></mat-error>
        </mat-form-field>
      </div>
    }

    @if (selectedModule$ | async | hasResponsibilityCheck) {
      <div class="address-captcha-container">
        <app-ngx-turnstile
          [siteKey]="turnstileSiteKey"
          (resolved)="onTokenResolve($event)"
          [formGroup]="addressForm"
          theme="light" />
      </div>
    }
  </form>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled:
        allTouched
        | nextButtonDisabledAddress
          : selectedModule
          : !!turnstileCaptchaToken
          : addressForm.valid
          : loading,
    }"
    (previous)="previous()"
    (next)="next()"></app-form-navigation>
</app-form-card>
