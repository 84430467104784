<!-- heat options -->
<app-summary-section
  heading="HEAT.HEAT_OPTIONS.HEADING"
  [stepRoute]="routes.HEAT_OPTIONS">
  <span class="chip">
    {{
      'HEAT.HEAT_OPTIONS.' + formData.heatOptions?.heatOption + '.LABEL'
        | translate
    }}
  </span>
  @if (formData.heatOptions?.heatOption === heatOptions.CHANGE) {
    @if (formData.heatOptions?.changeHeatPower) {
      <p class="section-heading">
        {{
          'HEAT.HEAT_OPTIONS.HEAT_CHANGE.CHANGE_HEAT_POWER_BEFORE_AFTER_LABEL'
            | translate
        }}
      </p>
      <div class="section-fields-container">
        <app-summary-section-field
          label="HEAT.HEAT_OPTIONS.HEAT_CHANGE.CURRENT_HEAT_POWER_PLACEHOLDER"
          [value]="formData.heatOptions?.currentHeatPower">
        </app-summary-section-field>
        <app-summary-section-field
          label="HEAT.HEAT_OPTIONS.HEAT_CHANGE.PLANNED_HEAT_POWER_PLACEHOLDER"
          [value]="formData.heatOptions?.plannedHeatPower">
        </app-summary-section-field>
        <app-summary-section-field
          label="HEAT.HEAT_OPTIONS.HEAT_CHANGE.ADDITIONAL_DEMAND_QUESTION"
          [value]="formData.heatOptions?.additionalHeatDemand">
        </app-summary-section-field>
      </div>
    }
    @if (formData.heatOptions?.otherChanges) {
      <p class="section-heading">
        {{ 'HEAT.HEAT_OPTIONS.HEAT_CHANGE.OTHER_CHANGES_LABEL' | translate }}
      </p>
      <div class="section-fields-container">
        <app-summary-section-field
          label="HEAT.HEAT_OPTIONS.HEAT_CHANGE.OTHER_CHANGES_PLACEHOLDER"
          [value]="formData.heatOptions?.otherChanges">
        </app-summary-section-field>
      </div>
    }
  }
  @if (formData.heatOptions?.heatOption === heatOptions.DETACH) {
    <div class="section-fields-container">
      <app-summary-section-field
        label="HEAT.HEAT_OPTIONS.HEAT_DETACH.DECONSTRUCTION_TYPE_QUESTION"
        [value]="
          (formData.heatOptions?.permanentDeconstruction
            ? 'HEAT.HEAT_OPTIONS.HEAT_DETACH.PERMANENT_DECONSTRUCTION_LABEL'
            : 'HEAT.HEAT_OPTIONS.HEAT_DETACH.TEMPORARY_DECONSTRUCTION_LABEL'
          ) | translate
        ">
      </app-summary-section-field>
      @if (!formData.heatOptions?.permanentDeconstruction) {
        <app-summary-section-field
          label="HEAT.HEAT_OPTIONS.HEAT_DETACH.DECONSTRUCTION_UNTIL"
          [value]="
            (formData.heatOptions?.deconstructionUntil
              | date: 'shortDate' : '' : currentLanguageCulture) || '-'
          ">
        </app-summary-section-field>
      }
      <app-summary-section-field
        label="HEAT.HEAT_OPTIONS.HEAT_DETACH.METER_NUMBER"
        [value]="formData.heatOptions?.meterNumber">
      </app-summary-section-field>
    </div>
  }
</app-summary-section>

<!-- heat property details -->
@if (
  formData.heatOptions?.heatOption === heatOptions.NEW ||
  formData.heatOptions?.heatOption === heatOptions.CHANGE
) {
  <app-summary-section
    heading="HEAT.HEAT_PROPERTY_TYPE.HEADING"
    [stepRoute]="routes.HEAT_PROPERTY_TYPE">
    <app-summary-building-type
      [buildingType]="
        formData.heatBuildingDetails?.buildingType
      "></app-summary-building-type>
    <div class="section-fields-container">
      <app-summary-section-field
        label="SHARED.BUILDING_STRUCTURE.LABEL"
        [value]="
          'SHARED.BUILDING_STRUCTURE.' +
            formData.heatBuildingDetails?.buildingStructure | translate
        ">
      </app-summary-section-field>
    </div>
  </app-summary-section>
}

<!-- heat requirements -->
@if (formData.heatOptions?.heatOption === heatOptions.NEW) {
  <app-summary-section
    heading="HEAT.HEAT_REQUIREMENTS.HEADING"
    [stepRoute]="routes.HEAT_REQUIREMENTS">
    <p class="section-heading">
      {{ 'HEAT.HEAT_REQUIREMENTS.SUBHEADING_CONSUMPTION' | translate }}
    </p>
    <div class="section-fields-container">
      <app-summary-section-field
        label="HEAT.HEAT_REQUIREMENTS.OUTPUT_DEMAND"
        [value]="formData.heatRequirementDetails?.heatOutputDemand">
      </app-summary-section-field>
    </div>
    <p class="section-heading">
      {{ 'HEAT.HEAT_REQUIREMENTS.OTHER_CONSUMERS' | translate }}
    </p>
    <div class="section-fields-container">
      <app-summary-section-field
        label="HEAT.HEAT_REQUIREMENTS.OTHER_CONSUMERS"
        [value]="formData.heatRequirementDetails?.otherConsumers">
      </app-summary-section-field>
      <app-summary-section-field
        label="HEAT.HEAT_REQUIREMENTS.OTHER_CONSUMERS_POWER"
        [value]="formData.heatRequirementDetails?.otherConsumersPower">
      </app-summary-section-field>
    </div>
    <p class="section-heading">
      {{ 'HEAT.HEAT_REQUIREMENTS.SUBHEADING_OTHER' | translate }}
    </p>
    <div class="section-fields-container">
      <app-summary-section-field
        label="HEAT.HEAT_REQUIREMENTS.NOTES"
        [value]="formData.heatRequirementDetails?.notes">
      </app-summary-section-field>
    </div>
  </app-summary-section>
}
