<div class="section-fields-container">
  <app-summary-section-field
    label="CONSTRUCTION_ELECTRICITY_INFORMATION.SYSTEM_POWER"
    [value]="constructionGridConnectionDetails.systemPower">
  </app-summary-section-field>

  <app-summary-section-field
    label="CONSTRUCTION_ELECTRICITY_INFORMATION.DEVICES_REQUIRE_APPROVAL_QUESTION"
    [value]="
      (constructionGridConnectionDetails.devicesRequireApproval
        ? 'COMMON.YES'
        : 'COMMON.NO'
      ) | translate
    ">
  </app-summary-section-field>

  @if (constructionGridConnectionDetails.devicesRequireApproval) {
    <app-summary-section-field
      label="CONSTRUCTION_ELECTRICITY_INFORMATION.REQUIRED_DEVICES"
      [value]="
        constructionGridConnectionDetails.requiredDevices?.join(', ')
      "></app-summary-section-field>
  }

  <app-summary-section-field
    label="CONSTRUCTION_ELECTRICITY_INFORMATION.DESIRED_INSTALLATION_DATE"
    [value]="
      (constructionGridConnectionDetails.desiredInstallationDate
        | date: 'shortDate' : '' : currentLanguageCulture) || '-'
    ">
  </app-summary-section-field>

  <app-summary-section-field
    label="CONSTRUCTION_ELECTRICITY_INFORMATION.DESIRED_DECONSTRUCTION_DATE"
    [value]="
      (constructionGridConnectionDetails.desiredDeconstructionDate
        | date: 'shortDate' : '' : currentLanguageCulture) || '-'
    ">
  </app-summary-section-field>
</div>
