<app-form-card
  titleIcon="home"
  cardTitle="COMMISSIONING_ELECTRICITY.MAIN_POWER_SUPPLY.HEADING"
  data-testid="commissioning-electricity-main-power-supply-heading">
  <h2 class="full-width">
    {{ 'COMMISSIONING_ELECTRICITY.MAIN_POWER_SUPPLY.DESCRIPTION' | translate }}
  </h2>
  <app-selection-group>
    @for (detail of details; track detail) {
      <app-selection-card
        [icon]="detail.icon"
        [label]="
          'COMMISSIONING_ELECTRICITY.MAIN_POWER_SUPPLY.TYPE.' + detail.type
        "
        [selected]="selectedType === detail.type"
        (click)="selectType(detail.type)"
        [attr.data-testid]="detail.type">
      </app-selection-card>
    }

    @if (allTouched && !selectedType) {
      <mat-error>{{ 'VALIDATION.REQUIRED_SELECTION' | translate }}</mat-error>
    }
  </app-selection-group>

  @if (selectedType) {
    <div class="space"></div>
    <app-hint>
      <span>{{
        'COMMISSIONING_ELECTRICITY.MAIN_POWER_SUPPLY.HINT.' + selectedType
          | translate
      }}</span>
    </app-hint>
  }

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: !selectedType,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
