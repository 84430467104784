<app-form-card
  titleIcon="category"
  cardTitle="GRID_CONNECTION_TYPE.HEADING"
  dataTestId="grid-connection-type-heading">
  <h2 class="full-width">
    {{ 'GRID_CONNECTION_TYPE.DESCRIPTION' | translate }}
  </h2>
  <app-selection-group>
    @for (connection of connectionDetailsList; track connection) {
      <app-selection-card
        class="selection-card"
        [icon]="connection.icon"
        [svgIcon]="connection.svgIcon"
        [label]="connection.label"
        [selected]="selectedType === connection.type"
        (click)="selectType(connection.type)"
        [attr.data-testid]="connection.type">
      </app-selection-card>
    }
    @if (
      allTouched | nextButtonDisabled: gridConnectionTypeFormControl.invalid
    ) {
      <mat-error>{{ 'VALIDATION.REQUIRED_SELECTION' | translate }}</mat-error>
    }
  </app-selection-group>

  @if (selectedType) {
    <div class="space"></div>
    <app-hint>
      @switch (selectedType) {
        @case (gridConnectionTypes.NEW) {
          <span>
            {{ 'SHARED.GRID_CONNECTION.MESSAGE' | translate }}
            <ul class="list-container">
              <li>
                {{ 'SHARED.DOCUMENT_TYPE.SITE_PLAN' | translate }}
              </li>
              <li>
                {{ 'SHARED.DOCUMENT_TYPE.FLOOR_PLAN' | translate }}
              </li>
            </ul>
          </span>
        }
        @default {
          <span>
            {{ 'GRID_CONNECTION_TYPE.INFO.' + selectedType | translate }}
          </span>
        }
      }
    </app-hint>
  }

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled:
        allTouched | nextButtonDisabled: gridConnectionTypeFormControl.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
