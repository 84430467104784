<section>
  <a
    [routerLink]="stepRoute ? '/' + stepRoute : null"
    [skipLocationChange]="true"
    class="section-title">
    <h1>{{ heading | translate }}</h1>

    <mat-icon
      aria-hidden="false"
      aria-label="navigate to step"
      [fontIcon]="icon"
      class="heading-icon material-symbols-outlined">
    </mat-icon>
  </a>
  <div>
    <ng-content></ng-content>
  </div>
  @if (showSeparator) {
    <hr />
  }
</section>
