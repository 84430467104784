<app-summary-section
  heading="CONTACT.CUSTOMER_DETAILS"
  [stepRoute]="contactRoute">
  <div class="section-fields-container">
    <app-summary-section-field
      label="CONTACT.REQUESTER"
      [value]="(contact.onBehalf ? 'COMMON.NO' : 'COMMON.YES') | translate">
    </app-summary-section-field>
  </div>

  <p class="section-heading">
    {{ 'CONTACT.CONTACT_DETAILS' | translate }}
  </p>

  <div class="section-fields-container">
    @if (contact.onBehalf) {
      <app-summary-section-field
        label="CONTACT.YOUR_FUNCTION"
        [value]="'CONTACT.ON_BEHALF_TYPE.' + contact.onBehalfType | translate">
      </app-summary-section-field>
      <app-summary-section-field
        label="CONTACT.POWER_OF_ATTORNEY"
        [value]="
          (contact.powerOfAttorney ? 'COMMON.YES' : 'COMMON.NO') | translate
        ">
      </app-summary-section-field>
      @if (contact.powerOfAttorney) {
        <app-summary-section-field
          label="CONTACT.POWER_OF_ATTORNEY_DOCUMENT"
          [value]="contact.powerOfAttorneyDocument?.name">
        </app-summary-section-field>
      }
    }
    <app-contact-details
      [contactDetails]="contact.contactForm"></app-contact-details>
  </div>

  <div class="section-fields-container">
    <app-summary-section-field
      label="CONTACT.CONNECTION_ADDRESS_DESCRIPTION"
      [value]="
        (contact.differentAddress ? 'COMMON.NO' : 'COMMON.YES') | translate
      ">
    </app-summary-section-field>
  </div>

  @if (contact.differentAddress && contact.differentAddressForm) {
    <div class="section-fields-container">
      <app-address [address]="contact.differentAddressForm"></app-address>
    </div>
  }

  @if (contact.onBehalf) {
    <p class="section-heading">
      {{ 'CONTACT.REQUESTER_CONTACT' | translate }}
    </p>
    <div class="section-fields-container">
      <app-summary-section-field
        label="CONTACT.FIRST_NAME"
        [value]="contact.differentContactForm?.firstName">
      </app-summary-section-field>
      <app-summary-section-field
        label="CONTACT.LAST_NAME"
        [value]="contact.differentContactForm?.lastName">
      </app-summary-section-field>
      <app-summary-section-field
        label="CONTACT.EMAIL"
        [value]="contact.differentContactForm?.email">
      </app-summary-section-field>
      <app-summary-section-field
        label="CONTACT.PHONE"
        [value]="contact.differentContactForm?.phone">
      </app-summary-section-field>
    </div>
  }
</app-summary-section>
