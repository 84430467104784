<div
  class="selection-card"
  [class.selected-card]="selected"
  [class.vertical]="vertical"
  [class.horizontal]="!vertical"
  data-testid="selection-card">
  <div
    class="selection-card-icon-container"
    [class.selected-card]="selected"
    [class.vertical]="vertical"
    [class.horizontal]="!vertical">
    <mat-icon
      aria-hidden="false"
      aria-label="selection-card icon"
      [fontIcon]="icon || ''"
      [svgIcon]="svgIcon || ''"
      class="selection-card-icon material-icons-outlined"
      [class.material-symbols-outlined]="symbolOutlinedIcon"
      [class.vertical]="vertical"
      [class.horizontal]="!vertical"></mat-icon>
  </div>
  <div
    class="selection-card-name-container"
    [lang]="currentLang"
    [class.vertical]="vertical"
    [class.horizontal]="!vertical">
    <span>
      {{ label | translate }}
    </span>
    @if (showCheckbox) {
      <mat-checkbox
        class="fake-checkbox"
        color="primary"
        [checked]="selected"></mat-checkbox>
    }
  </div>
</div>
