<app-form-card
  titleIcon="category"
  cardTitle="PV_TYPE.HEADING"
  dataTestId="pv-type-heading">
  <h2 class="full-width">
    {{ 'PV_TYPE.DESCRIPTION' | translate }}
  </h2>
  <app-selection-group>
    @for (connection of connectionDetailsList; track connection) {
      <app-selection-card
        class="selection-card"
        [icon]="connection.icon"
        [svgIcon]="connection.svgIcon"
        [label]="connection.label"
        [selected]="selectedType === connection.type"
        (click)="selectType(connection.type)"
        [attr.data-testid]="connection.type">
      </app-selection-card>
    }
    @if (allTouched | nextButtonDisabled: pvTypeFormControl.invalid) {
      <mat-error>{{ 'VALIDATION.REQUIRED_SELECTION' | translate }}</mat-error>
    }
  </app-selection-group>

  @if (selectedType) {
    <div class="space"></div>
    <app-hint>
      {{ 'PV_TYPE.INFO.' + selectedType | translate }}
    </app-hint>
    <div class="space"></div>
  }

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: pvTypeFormControl.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
