import { Injectable } from '@angular/core';
import {
  AppState,
  FLOW,
  FLOW_MODULE,
  FormDataObject,
  OtherData,
} from '@app/models/form-data.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { PublicCustomerConfigurationDto } from '@app/services/white-label.service';
import { DOCUMENT_TYPE } from '@app/models/registration-form';

export type PartialFormData = Partial<FormDataObject>;
export type PartialOtherData = Partial<OtherData>;

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  private initialFormData: FormDataObject = {};
  private initialOtherData: OtherData = {};
  private initialState: AppState = {
    formData: this.initialFormData,
    otherData: this.initialOtherData,
  };

  private state$ = new BehaviorSubject<AppState>(this.initialState);
  private readonly cacheableDocumentTypes = [
    DOCUMENT_TYPE.SITE_PLAN,
    DOCUMENT_TYPE.FLOOR_PLAN,
  ];

  // constructor() {
  //   this.initFromStorage();
  // }

  // private initFromStorage(): void {
  //   const formDataJson = localStorage.getItem('formData');
  //   const formData = formDataJson && JSON.parse(formDataJson);
  //
  //   if (formData) {
  //     this.updateFormData(formData as PartialFormData);
  //   }
  // }

  public getInitialFormData(): FormDataObject {
    return this.initialFormData;
  }

  public observeState(): Observable<AppState> {
    return this.state$.asObservable();
  }

  public updateFormData(formStepData: PartialFormData): void {
    const formData = { ...this.state$.value.formData, ...formStepData };
    this.state$.next({ formData, otherData: this.state$.value.otherData });
  }

  public updateOtherData(newOtherData: PartialOtherData): void {
    const otherData = { ...this.state$.value.otherData, ...newOtherData };

    this.state$.next({ formData: this.state$.value.formData, otherData });
  }

  public isModified(): boolean {
    return (
      JSON.stringify(this.state$.value.formData) !==
      JSON.stringify(this.initialFormData)
    );
  }

  public clearState(clearModule = true, clearFlow = true): void {
    const formData: FormDataObject = {
      address: this.state$.value.formData.address,
      contact: this.state$.value.formData.contact,
      commissioningElectricityContact:
        this.state$.value.formData.commissioningElectricityContact,
      selectedModule: clearModule
        ? undefined
        : this.state$.value.formData.selectedModule,
      selectedFlow: clearFlow
        ? undefined
        : this.state$.value.formData.selectedFlow,
      documentDetails: this.state$.value.formData.documentDetails?.documents
        ?.length
        ? {
            documents:
              this.state$.value.formData.documentDetails.documents.filter(
                document => this.cacheableDocumentTypes.includes(document.type)
              ),
          }
        : undefined,
    };

    this.state$.next({ formData, otherData: this.initialOtherData });
  }

  public isFlowAvailable(
    flow: FLOW,
    customerConfigurationDto?: PublicCustomerConfigurationDto
  ): boolean {
    switch (flow) {
      case FLOW.COMMISSIONING:
        return (
          (customerConfigurationDto?.commissioningModules?.length ?? 0) > 0
        );
      case FLOW.REGISTRATION:
        return (customerConfigurationDto?.moduleSettings.length ?? 0) > 0;
      default:
        return false;
    }
  }
  private isModuleAvailable(
    selectedModule: FLOW_MODULE,
    customerConfigurationDto: PublicCustomerConfigurationDto
  ): { isCommissioningAvailable: boolean; isRegistrationAvailable: boolean } {
    const isCommissioningAvailable =
      customerConfigurationDto.commissioningModules.includes(selectedModule);
    const isRegistrationAvailable = customerConfigurationDto.moduleSettings
      ?.map(setting => setting.module)
      .includes(selectedModule);

    return { isCommissioningAvailable, isRegistrationAvailable };
  }

  public evaluateFlowAndUpdateModuleInState(
    selectedModule: FLOW_MODULE | undefined,
    customerConfigurationDto: PublicCustomerConfigurationDto | undefined
  ): void {
    if (!selectedModule || !customerConfigurationDto) {
      return;
    }

    const { isCommissioningAvailable, isRegistrationAvailable } =
      this.isModuleAvailable(selectedModule, customerConfigurationDto);

    if (isCommissioningAvailable && !isRegistrationAvailable) {
      this.updateFormData({ selectedFlow: FLOW.COMMISSIONING });
    } else if (!isCommissioningAvailable && isRegistrationAvailable) {
      this.updateFormData({ selectedFlow: FLOW.REGISTRATION });
    }
  }

  public isFlowSelectionAvailableForSelectedModule(
    selectedModule: FLOW_MODULE | undefined,
    customerConfigurationDto: PublicCustomerConfigurationDto | undefined
  ): boolean {
    if (!selectedModule || !customerConfigurationDto) {
      return false;
    }

    const { isCommissioningAvailable, isRegistrationAvailable } =
      this.isModuleAvailable(selectedModule, customerConfigurationDto);

    return isCommissioningAvailable && isRegistrationAvailable;
  }
}
