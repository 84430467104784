<div class="summary-section-field-container">
  @if (innerHTMLLabel) {
    <p class="label" [innerHTML]="innerHTMLLabel | translate"></p>
  } @else {
    <p class="label">
      {{ label ? (label | translate) : '-' }}
    </p>
  }

  <p class="bold">{{ value ?? '-' }}</p>
</div>
