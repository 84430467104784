import { Inject, Injectable } from '@angular/core';
// @ts-expect-error needs investigation on way of import
import tinycolor from 'tinycolor2';
import { BehaviorSubject, finalize, Observable, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '@app/services/environment.service';
import { DOCUMENT } from '@angular/common';
import { FLOW_MODULE } from '@app/models/form-data.interface';
import { FORM_TYPE } from '@app/models/registration-form';
import { differenceInMinutes, parseISO } from 'date-fns';

interface Color {
  name: string;
  hex: string;
  darkContrast: boolean;
}

export interface PublicCustomerConfigurationDto {
  id: string;
  customerId: string;
  customerName: string;
  moduleSettings: ModuleSettingDto[];
  commissioningModules: FLOW_MODULE[];
  whitelabelSettings: WhitelabelSettingsDto;
  createdAt: string;
  lastUpdatedAt: string;
  documentsUploadEnabled: boolean;
}

export interface ModuleSettingDto {
  id: string;
  module: FLOW_MODULE;
  disabledFlows: FORM_TYPE[];
  createdAt: string;
  lastUpdatedAt: string;
}

export interface WhitelabelSettingsDto {
  host: string;
  colorSettings: ColorSettingsDto;
  legalSettings: LegalSettingsDto;
  assetSettings: AssetSettingsDto;
}

export interface ColorSettingsDto {
  primary: string;
  secondary: string;
}

export interface LegalSettingsDto {
  termsConditionsURL?: string;
  dataProtectionURL?: string;
  imprintURL?: string;
}

export interface AssetSettingsDto {
  logoUrl?: string;
  faviconUrl?: string;
  showLogo: boolean;
  maxLogoSize?: string;
}

@Injectable({
  providedIn: 'root',
})
export class WhiteLabelService {
  private _configured$: ReplaySubject<void> = new ReplaySubject(1);
  private _whiteLabelConfiguration$: BehaviorSubject<
    PublicCustomerConfigurationDto | undefined
  > = new BehaviorSubject<PublicCustomerConfigurationDto | undefined>(
    undefined
  );

  private readonly faviconElementId = 'favicon';
  private readonly window: Window;

  constructor(
    private http: HttpClient,
    private environment: EnvironmentService,
    @Inject(DOCUMENT) private documentToken: Document
  ) {
    this.window = this.documentToken.defaultView as Window;
    if (this.isCached()) {
      try {
        this.initFromCache();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        this.initFromServer();
      }
    } else {
      this.initFromServer();
    }
  }

  private initFromCache(): void {
    const whiteLabelJson =
      this.window.sessionStorage.getItem('white-label-configuration') ?? '';

    const publicCustomerConfigurationDto = JSON.parse(
      whiteLabelJson
    ) as PublicCustomerConfigurationDto;
    this.setWhitelabel(publicCustomerConfigurationDto);
  }

  private initFromServer(): void {
    this.resolveHostOverride();
    const host = this.environment.hostOverride ?? this.window.location.host;

    this.http
      .get<PublicCustomerConfigurationDto>(
        `${this.environment.apiUrl}/host-configurations/${host}`
      )
      .pipe(finalize(() => this.finishLoading()))
      .subscribe({
        next: whiteLabel => {
          this.updateCache(whiteLabel);
          this.setWhitelabel(whiteLabel);
        },
        // eslint-disable-next-line no-console
        error: error => console.error(error),
      });
  }

  private resolveHostOverride(): void {
    const params = new URLSearchParams(this.window.location.search);

    if (
      this.environment.isDynamicHostOverrideEnabled &&
      params.has('hostOverride')
    ) {
      this.environment.hostOverride = params.get('hostOverride') ?? undefined;
      window.history.pushState({}, document.title, '/');
    }
  }

  private isCached(): boolean {
    try {
      const cachedDomain = this.window.sessionStorage.getItem(
        'white-label-cached-host'
      );
      const domainFound =
        cachedDomain && cachedDomain === this.window.location.host;

      if (!domainFound) {
        return false;
      }
      const now = new Date();
      const cacheDate = parseISO(
        this.window.sessionStorage.getItem('white-label-cache-date') ?? ''
      );
      return differenceInMinutes(now, cacheDate) < 5;
    } catch {
      // eslint-disable-next-line no-console
      console.error(JSON.stringify(this.window.sessionStorage));
      return false;
    }
  }

  private updateCache(
    whiteLabelConfiguration: PublicCustomerConfigurationDto
  ): void {
    this.window.sessionStorage.setItem(
      'white-label-cached-host',
      whiteLabelConfiguration.whitelabelSettings.host
    );
    this.window.sessionStorage.setItem(
      'white-label-cache-date',
      new Date().toISOString()
    );
    this.window.sessionStorage.setItem(
      'white-label-configuration',
      JSON.stringify(whiteLabelConfiguration)
    );
  }

  public get configured$(): Observable<void> {
    return this._configured$.asObservable();
  }

  public get whiteLabelConfig$(): Observable<
    PublicCustomerConfigurationDto | undefined
  > {
    return this._whiteLabelConfiguration$.asObservable();
  }

  public hasWhitelabel(): boolean {
    return !!this._whiteLabelConfiguration$.value;
  }

  private createFavicon(assetSettingsDto: AssetSettingsDto): void {
    if (!assetSettingsDto.faviconUrl) {
      return;
    }
    const existingFavicon = document.getElementById(this.faviconElementId);

    if (existingFavicon) {
      existingFavicon.remove();
    }
    const faviconElement = document.createElement('link');

    faviconElement.setAttribute('id', this.faviconElementId);
    faviconElement.setAttribute('rel', 'icon');
    faviconElement.setAttribute('type', 'image/x-icon');
    faviconElement.setAttribute('href', assetSettingsDto.faviconUrl);
    document.head.appendChild(faviconElement);
  }

  private setWhitelabel(
    whiteLabelConfig: PublicCustomerConfigurationDto
  ): void {
    this.loadTheme(whiteLabelConfig);
    this._whiteLabelConfiguration$.next(whiteLabelConfig);
  }

  private setAssets(
    whiteLabelConfiguration: PublicCustomerConfigurationDto
  ): void {
    this.createFavicon(
      whiteLabelConfiguration.whitelabelSettings.assetSettings
    );
  }

  private loadTheme(
    whiteLabelConfiguration: PublicCustomerConfigurationDto
  ): void {
    this.setPalette(
      whiteLabelConfiguration.whitelabelSettings.colorSettings.primary,
      'primary'
    );
    this.setPalette(
      whiteLabelConfiguration.whitelabelSettings.colorSettings.secondary,
      'secondary'
    );
    this.setAssets(whiteLabelConfiguration);
    this.finishLoading();
  }

  private finishLoading(): void {
    if (!this._configured$.closed) {
      this._configured$.next();
      this._configured$.complete();
    }
  }

  private setPalette(baseColor: string, name: string): void {
    const darken = name === 'primary';
    const colorPalette = this.computeColors(baseColor, darken);

    for (const color of colorPalette) {
      const colorKey = `--theme-${name}-${color.name}`;
      const colorValue = color.hex;
      const colorKeyContrast = `--theme-${name}-contrast-${color.name}`;
      const colorValueContrast = tinycolor(color.hex).isLight()
        ? 'var(--portal-black)'
        : 'var(--portal-white)';

      document.documentElement.style.setProperty(colorKey, colorValue);
      document.documentElement.style.setProperty(
        colorKeyContrast,
        colorValueContrast
      );
    }
    document.documentElement.style.setProperty(
      `--white-label-${name}`,
      baseColor
    );
  }

  private computeColors(hex: string, darken: boolean): Color[] {
    if (darken) {
      return [
        this.getColorObject(tinycolor(hex).darken(52), '50'),
        this.getColorObject(tinycolor(hex).darken(37), '100'),
        this.getColorObject(tinycolor(hex).darken(26), '200'),
        this.getColorObject(tinycolor(hex).darken(12), '300'),
        this.getColorObject(tinycolor(hex).darken(6), '400'),
        this.getColorObject(tinycolor(hex), '500'),
        this.getColorObject(tinycolor(hex).lighten(6), '600'),
        this.getColorObject(tinycolor(hex).lighten(12), '700'),
        this.getColorObject(tinycolor(hex).lighten(18), '800'),
        this.getColorObject(tinycolor(hex).lighten(24), '900'),
        this.getColorObject(tinycolor(hex).darken(50).saturate(30), 'A100'),
        this.getColorObject(tinycolor(hex).darken(30).saturate(30), 'A200'),
        this.getColorObject(tinycolor(hex).darken(10).saturate(15), 'A400'),
        this.getColorObject(tinycolor(hex).darken(5).saturate(5), 'A700'),
      ];
    } else {
      return [
        this.getColorObject(tinycolor(hex).lighten(52), '50'),
        this.getColorObject(tinycolor(hex).lighten(37), '100'),
        this.getColorObject(tinycolor(hex).lighten(26), '200'),
        this.getColorObject(tinycolor(hex).lighten(12), '300'),
        this.getColorObject(tinycolor(hex).lighten(6), '400'),
        this.getColorObject(tinycolor(hex), '500'),
        this.getColorObject(tinycolor(hex).darken(6), '600'),
        this.getColorObject(tinycolor(hex).darken(12), '700'),
        this.getColorObject(tinycolor(hex).darken(18), '800'),
        this.getColorObject(tinycolor(hex).darken(24), '900'),
        this.getColorObject(tinycolor(hex).lighten(50).saturate(30), 'A100'),
        this.getColorObject(tinycolor(hex).lighten(30).saturate(30), 'A200'),
        this.getColorObject(tinycolor(hex).lighten(10).saturate(15), 'A400'),
        this.getColorObject(tinycolor(hex).lighten(5).saturate(5), 'A700'),
      ];
    }
  }

  private getColorObject(value: string, name: string): Color {
    const color = tinycolor(value);

    return {
      name,
      hex: color.toHexString(),
      darkContrast: color.isLight(),
    };
  }
}
